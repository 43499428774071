import IconAdd from './files/add-icon.svg';
import AnalysisIllustration from './files/analysis-illustration.svg';
import AnalyzingSpinner from './files/analyzing-spinner.svg';
import ErrorIcon from './files/big-info-error.svg';
import BigInfoGrey from './files/big-info-grey.svg';
import IconBlurOn from './files/blur_on.svg';
import Bubbles from './files/bubbles.svg';
import Comment from './files/comment.svg';
// // Specifically used for select component (multi) styling
import AmazonS3 from './files/amazon-s3.svg';
import IconStopAudio from './files/audio-stop.svg';
import BarChartIllustration from './files/bar-chart-illustration.svg';
import IconBookmarks from './files/bookmarks-icon.svg';
import BoxPlotIllustration from './files/box-plot-illustration.svg';
import BubbleChartIllustration from './files/bubble-chart-illustration.svg';
import IconCurveChart from './files/chart-curve.svg';
import ChatBubble from './files/chat-bubble.svg';
import Checkmark from './files/checkmark.svg';
import ChevronDown from './files/chevron-down.svg';
import ChevronRight from './files/chevron-right.svg';
import IconCircleThreeDots from './files/circle-three-dots.svg';
import IconCirclesCluster from './files/circles-cluster.svg';
import ClassDistributionIllustrationV2 from './files/class-distribution-illustration-v2.svg';
import ClassDistributionIllustration from './files/class-distribution-illustration.svg';
import IconCode from './files/code.svg';
import CopyToClipboard from './files/copy-to-clipboard.svg';
import CorrelationIllustration from './files/correlation-illustration.svg';
import CsvFile from './files/csv-file.svg';
import CsvUrl from './files/csv-url.svg';
import IconGradientCube from './files/cube.svg';
import CustomApps from './files/custom-apps.svg';
import DataProfileIllustration from './files/data-profile-illustration.svg';
import IllustrationDatasetRegError from './files/dataset-registration-error.svg';
import IllustrationDatasetRegPlain from './files/dataset-registration-plain.svg';
import IllustrationDatasetRegSuccess from './files/dataset-registration-success.svg';
import Delete from './files/delete.svg';
import IconDeviceHub from './files/device_hub.svg';
import IconDocsAndSupport from './files/docs-support.svg';
import IconDocs from './files/docs.svg';
import IconGradientDocument from './files/document.svg';
import DragHandle from './files/drag-handle.svg';
import Email from './files/email.svg';
import EmbeddingIllustration from './files/embedding-illustration.svg';
import EmptyCorrelations from './files/empty-correlations.svg';
import IconNotSupported from './files/empty-icon.svg';
import EmptyRecordingIcon from './files/empty-recording.svg';
import Expand from './files/expand.svg';
import IconFairyWand from './files/fairy-wand-icon.svg';
import IconFileUpload from './files/file-upload.svg';
import Filter from './files/filter.svg';
import GoogleSheet from './files/google-sheet.svg';
import IconGroupAdd from './files/group_add.svg';
import HotelIcon from './files/hotel.svg';
import Hubspot from './files/hubspot.svg';
import IconAnalytics from './files/icon-analytics.svg';
import IconAppStore from './files/icon-app-store.svg';
import IconAtom from './files/icon-atom.svg';
import IconBulb from './files/icon-bulb.svg';
import IconCursor from './files/icon-cursor.svg';
import IconDashboardNew from './files/icon-dashboard-new.svg';
import IconDashboard from './files/icon-dashboard.svg';
import IconDataLabeling from './files/icon-data-labeling.svg';
import IconDelete from './files/icon-delete.svg';
import IconDoc from './files/icon-doc.svg';
import IconEdit from './files/icon-edit.svg';
import IconExpand from './files/icon-expand.svg';
import IconFairyWand2 from './files/icon-fairy-wand.svg';
import IconMarkovAppStore from './files/icon-markov-app-store.svg';
import IconMore from './files/icon-more.svg';
import IconOperators from './files/icon-operators.svg';
import IconOrangeWarning from './files/icon-orange-warning.svg';
import IconPlayFilled from './files/icon-play-filled.svg';
import IconRead from './files/icon-read.svg';
import IconRocket from './files/icon-rocket.svg';
import IconRun from './files/icon-run.svg';
import IconScore from './files/icon-score.svg';
import IconSnippetsNew from './files/icon-snippets-new.svg';
import IconSnippets from './files/icon-snippets.svg';
import IconStars from './files/icon-stars.svg';
import IconStorageNew from './files/icon-storage-new.svg';
import IconViewMore from './files/icon-view-more.svg';
import IconWorkflowsOnboardingGmail from './files/icon-workflows-onboarding-gmail.svg';
import IconWorkflowsOnboardingOpenai from './files/icon-workflows-onboarding-openai.svg';
import IconWorkflowsOnboardingPdf from './files/icon-workflows-onboarding-pdf.svg';
import GoogleDrive from './files/icons-google-drive.svg';
import IllustrationAutoGrade from './files/illustration-auto-grade.svg';
import IllustrationChatWithMarko from './files/illustration-chat-with-marko.svg';
import IllustrationCopyEdit from './files/illustration-copy-edit.svg';
import DataIllustration from './files/illustration-data.svg';
import IllustrationIconsAppStore from './files/illustration-icons-app-store.svg';
import IllustrationLessonPlanner from './files/illustration-lesson-planner.svg';
import ModelIllustration from './files/illustration-model.svg';
import IllustrationQnaWithPdf from './files/illustration-qna-with-pdf.svg';
import IllustrationRetailAi from './files/illustration-retailai.svg';
import SnippetIllustration from './files/illustration-snippet.svg';
import IllustrationSummarize from './files/illustration-summarize.svg';
import IllustrationSupplementGenerator from './files/illustration-supplement-generator.svg';
import IllustrationTeacherAssistant from './files/illustration-teacher-assistant.svg';
import IllustrationWorkflowsEmptyBuilder from './files/Illustration-workflows-empty-builder.svg';
import IconInsights from './files/insights.svg';
import IconGradientIntersectingCurves from './files/intersecting-curves.svg';
import IconGradientLattice from './files/lattice.svg';
import LeftArrow from './files/left-arrow.svg';
import LineChartIllustration from './files/line-chart-illustration.svg';
import IconLongArrowRight from './files/long-arrow-right.svg';
import Machine from './files/machine.svg';
import MarkovLogo from './files/markov-logo.svg';
import MarkovStarsFull from './files/markov-stars-full.svg';
import MarkovStarsPink from './files/markov-stars-pink.svg';
import MarkovStars from './files/markov-stars.svg';
import FullLogoWhite from './files/markovml-full-logo-white.svg';
import IconWhatsNew from './files/megaphone.svg';
import MissingValuesIllustration from './files/missing-values-illustration.svg';
import IconModelApp from './files/model-apps-icon.svg';
import ModelIcon from './files/model-icon.svg';
import NewsTracker from './files/news-tracker.svg';
import NodeGraph from './files/node-graph.svg';
import IconNotAllowed from './files/not-allowed.svg';
import IconNoteCode from './files/note-code.svg';
import IconRoundedNotes from './files/notes-rounded.svg';
import Notes from './files/notes.svg';
import PencilBar from './files/pencil-bar.svg';
import IconPhotoFilter from './files/photo_filter.svg';
import IconPlayAudio from './files/play-audio.svg';
import IconPostgres from './files/postgresql.svg';
import ProgressCircle from './files/progress-circle.svg';
import ReOrder from './files/re-order.svg';
import IconRestore from './files/restore.svg';
import RightArrow from './files/right-arrow.svg';
import IconGradientRocket from './files/rocket.svg';
import Search from './files/search.svg';
import SegmentSimilarityIllustrationV2 from './files/segment-similarity-illustration-v2.svg';
import SegmentSimilarityIllustration from './files/segment-similarity-illustration.svg';
import SendIcon from './files/send-icon.svg';
import IconSettings from './files/settings.svg';
import IconShrinkingLines from './files/shrinking-lines.svg';
import SlackLogo from './files/slack-logo.svg';
import IconSmile from './files/smile.svg';
import IconSnippetsV2 from './files/snippets.svg';
import SnowflakeLogo from './files/snowflake-logo.svg';
import Sparkles from './files/sparks.svg';
import Stack from './files/stack.svg';
import StopIllustration from './files/stop-illustration.svg';
import IconStorage from './files/storage.svg';
import IconSystemUpdate from './files/system-update.svg';
import TableIllustration from './files/table-illustration.svg';
import IconTextLines from './files/text-lines.svg';
import Todo from './files/todo.svg';
import IconTranslate from './files/translate.svg';
import VizErrorState from './files/viz-error-state.svg';
import WorkflowGraph from './files/workflow-graph.svg';

export { default as Arrow } from './components/Arrow';
export { default as Close } from './components/Close';
export { default as Info } from './components/Info';

export const AirbyteStorageIcons = {
  // match with BE enum values. Key value are not same :(
  hubspot: Hubspot,
};

export {
  AmazonS3,
  AnalysisIllustration,
  AnalyzingSpinner,
  BarChartIllustration,
  BigInfoGrey,
  BoxPlotIllustration,
  BubbleChartIllustration,
  Bubbles,
  ChatBubble,
  Checkmark,
  ChevronDown,
  ChevronRight,
  ClassDistributionIllustration,
  ClassDistributionIllustrationV2,
  Comment,
  CopyToClipboard,
  CorrelationIllustration,
  CsvFile,
  CsvUrl,
  CustomApps,
  DataIllustration,
  DataProfileIllustration,
  Delete,
  DragHandle,
  Email,
  EmbeddingIllustration,
  EmptyCorrelations,
  EmptyRecordingIcon,
  ErrorIcon,
  Expand,
  Filter,
  FullLogoWhite,
  GoogleDrive,
  GoogleSheet,
  HotelIcon,
  IconAdd,
  IconAnalytics,
  IconAppStore,
  IconAtom,
  IconBlurOn,
  IconBookmarks,
  IconBulb,
  IconCircleThreeDots,
  IconCirclesCluster,
  IconCode,
  IconCursor,
  IconCurveChart,
  IconDashboard,
  IconDashboardNew,
  IconDataLabeling,
  IconDelete,
  IconDeviceHub,
  IconDoc,
  IconDocs,
  IconDocsAndSupport,
  IconEdit,
  IconExpand,
  IconFairyWand,
  IconFairyWand2,
  IconFileUpload,
  IconGradientCube,
  IconGradientDocument,
  IconGradientIntersectingCurves,
  IconGradientLattice,
  IconGradientRocket,
  IconGroupAdd,
  IconInsights,
  IconLongArrowRight,
  IconMarkovAppStore,
  IconModelApp,
  IconMore,
  IconNotAllowed,
  IconNotSupported,
  IconNoteCode,
  IconOperators,
  IconOrangeWarning,
  IconPhotoFilter,
  IconPlayAudio,
  IconPlayFilled,
  IconPostgres,
  IconRead,
  IconRestore,
  IconRocket,
  IconRoundedNotes,
  IconRun,
  IconScore,
  IconSettings,
  IconShrinkingLines,
  IconSmile,
  IconSnippets,
  IconSnippetsNew,
  IconSnippetsV2,
  IconStars,
  IconStopAudio,
  IconStorage,
  IconStorageNew,
  IconSystemUpdate,
  IconTextLines,
  IconTranslate,
  IconViewMore,
  IconWhatsNew,
  IconWorkflowsOnboardingGmail,
  IconWorkflowsOnboardingOpenai,
  IconWorkflowsOnboardingPdf,
  IllustrationAutoGrade,
  IllustrationChatWithMarko,
  IllustrationCopyEdit,
  IllustrationDatasetRegError,
  IllustrationDatasetRegPlain,
  IllustrationDatasetRegSuccess,
  IllustrationIconsAppStore,
  IllustrationLessonPlanner,
  IllustrationQnaWithPdf,
  IllustrationRetailAi,
  IllustrationSummarize,
  IllustrationSupplementGenerator,
  IllustrationTeacherAssistant,
  IllustrationWorkflowsEmptyBuilder,
  LeftArrow,
  LineChartIllustration,
  Machine,
  MarkovLogo,
  MarkovStars,
  MarkovStarsFull,
  MarkovStarsPink,
  MissingValuesIllustration,
  ModelIcon,
  ModelIllustration,
  NewsTracker,
  NodeGraph,
  Notes,
  PencilBar,
  ProgressCircle,
  ReOrder,
  RightArrow,
  Search,
  SegmentSimilarityIllustration,
  SegmentSimilarityIllustrationV2,
  SendIcon,
  SlackLogo,
  SnippetIllustration,
  SnowflakeLogo,
  Sparkles,
  Stack,
  StopIllustration,
  TableIllustration,
  Todo,
  VizErrorState,
  WorkflowGraph,
};
