import {
  IconAlarm,
  IconCircleCheckFilled,
  IconCircleMinus,
  IconClockStop,
  IconExclamationCircle,
  IconExclamationCircleFilled,
} from '@tabler/icons-react';
import _ from 'lodash';
import capitalize from 'lodash/capitalize';
import { AnalyzingSpinner } from '../../../../design-system';
import { Horizontal, Text, useMarkovTheme } from '../../../../design-system/v2';
import { MantineTheme } from '../../../../design-system/v2/styles';
import { ExecutionType, WorkflowRun, WorkflowRunStatus } from '../../../../generated/api';
import { convertDurationToShortFormat } from '../../../../lib/time-format';
import { formatDateTime } from '../../../../lib/ui';
import { WorkflowRunStatusBadge } from './WorkflowRunStatusBadge';

export const WorkflowRunDuration = ({ duration }: Record<'duration', string>) => {
  const theme = useMarkovTheme();

  return (
    <Horizontal spacing="xs" align="center">
      <IconAlarm color={theme.colors.gray[6]} size={16} />
      <Text variant="small03" color="gray.6" sx={{ lineHeight: '16px' }}>
        {convertDurationToShortFormat(duration)}
      </Text>
    </Horizontal>
  );
};

const getExecutionTypeLabel = (executionType: ExecutionType) => {
  if (executionType === ExecutionType.OneTime) {
    return 'Manual';
  }

  return _.startCase(capitalize(executionType));
};

export const getWorkflowRunIcon = (status: WorkflowRunStatus, theme: MantineTheme) => {
  switch (status) {
    case WorkflowRunStatus.Success:
      return <IconCircleCheckFilled size={16} color={theme.colors.green[6]} />;
    case WorkflowRunStatus.Running:
      return <AnalyzingSpinner width={16} height={16} />;
    case WorkflowRunStatus.ExecutionFailed:
    case WorkflowRunStatus.CreationFailed:
      return <IconExclamationCircleFilled size={16} color={theme.colors.red[5]} />;
    case WorkflowRunStatus.Created:
      return <AnalyzingSpinner width={16} height={16} />;
    case WorkflowRunStatus.NotFound:
      return <IconExclamationCircle size={16} color="gray" />;
    case WorkflowRunStatus.Terminated:
      return <IconClockStop size={16} color="gray" />;
    case WorkflowRunStatus.Scheduled:
      return <IconAlarm size={16} color="gray" />;
    case WorkflowRunStatus.Skipped:
      return <IconCircleMinus size={16} color="gray" />;
    default:
      return <IconExclamationCircle size={16} color="gray" />;
  }
};

export const getWorkflowListItemInfo = (run: WorkflowRun) => {
  const { status, startDate, scheduledTime, executionType, workflowRunId, totalDuration } = run;
  const theme = useMarkovTheme(); // Get the theme

  const icon = getWorkflowRunIcon(status, theme); // Reuse the icon function here

  let info;
  switch (status) {
    case WorkflowRunStatus.Success:
      info = {
        icon,
        info: <WorkflowRunDuration duration={totalDuration ?? '--'} />,
        subTitle: `Started at ${formatDateTime(startDate)}`,
      };
      break;
    case WorkflowRunStatus.CreationFailed:
    case WorkflowRunStatus.ExecutionFailed:
      info = {
        icon,
        titleColor: 'gray.8',
        info: <WorkflowRunStatusBadge status={status} />,
        subTitle: `Started at ${formatDateTime(startDate)}`,
      };
      break;
    case WorkflowRunStatus.Running:
      info = {
        icon,
        subTitle: `Running since ${formatDateTime(startDate)}`,
      };
      break;
    case WorkflowRunStatus.Scheduled:
      info = {
        icon,
        subTitle: `At ${formatDateTime(scheduledTime || '--')}`,
      };
      break;
    case WorkflowRunStatus.Terminated:
      info = {
        icon,
        subTitle: `Started at ${formatDateTime(startDate)}`,
      };
      break;
    default:
      info = {
        icon,
      };
  }

  const executionTypeLabel = getExecutionTypeLabel(executionType);
  const title = status == WorkflowRunStatus.Scheduled ? `Scheduled Run` : `Run ${run.runNumber}`;
  return { ...info, title, executionType: executionTypeLabel };
};
