import noop from 'lodash/noop';
import { Alert, Loader, Text, Vertical } from '../../../../../../../design-system/v2';
import { OperatorCategory, WorkflowRunOperatorStatus } from '../../../../../../../generated/api';
import {
  useDebugRunStatusQuery,
  useInitDebugMutation,
} from '../../../../../../../queries/workflows/debug';
import { useGetWorkflowDetailsQuery } from '../../../../../../../queries/workflows/detail/detail';
import { useDagIOSchemaQuery } from '../../../../../../../queries/workflows/operators';
import { DataPreviewContainer } from '../../../../debug-panel/logs-modal/DataPreview.container';
import { MissingDebugRun } from './MissingDebugRun';
import { NodeSchemaPreview } from './NodeSchemaPreview';

enum NodeTabs {
  Input = 'Input',
  Output = 'Output',
  Examples = 'Example',
}

interface NodeSchemaPreviewContainerProps {
  workflowId: string;
  runId?: string;
  nodeId: string;
  prevNodeId?: string;
  operatorCategory?: OperatorCategory;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  operatorId: string;
  isFormDirty?: boolean;
}

export const NodeSchemaPreviewContainer = ({
  workflowId,
  runId,
  nodeId,
  prevNodeId,
  operatorCategory,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  operatorId,
  isFormDirty,
}: NodeSchemaPreviewContainerProps) => {
  const {
    isLoading: runStatusLoading,
    isError: runStatusError,
    data: runStatus,
  } = useDebugRunStatusQuery(workflowId, runId);
  const { isLoading, isFetching, isError, data: ioSchema } = useDagIOSchemaQuery(workflowId);
  const {
    isLoading: loadingWFDetails,
    isError: wfDetailsError,
    data: wfDetails,
  } = useGetWorkflowDetailsQuery(workflowId);
  const { mutateAsync: initDebugWorkflow } = useInitDebugMutation(workflowId);

  const handleDebug = () => {
    initDebugWorkflow({});
  };

  if (isFormDirty && operatorCategory === OperatorCategory.Source) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Alert>There are some unsaved changes in the form. Please apply them</Alert>
      </Vertical>
    );
  }

  if (isLoading || isFetching || loadingWFDetails || runStatusLoading) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Loader variant="dots" size="xl" />
        <Alert>Loading data for the preview</Alert>
      </Vertical>
    );
  }

  const operatorStatus = runStatus?.operatorsStatus?.find(opStatus => opStatus.nodeId === nodeId);
  const nodeStatus = operatorStatus?.statusDetails.status;

  if (!ioSchema || !ioSchema[nodeId]) {
    const isDebugging =
      runStatusLoading ||
      nodeStatus === WorkflowRunOperatorStatus.Running ||
      nodeStatus === WorkflowRunOperatorStatus.NotStarted;
    return (
      <MissingDebugRun
        showDebugButton={!!wfDetails?.dagId}
        onRunDebug={handleDebug}
        isLoading={isDebugging}
      />
    );
  }

  const inputSchema = ioSchema[nodeId].inputSchema;
  const outputSchema = ioSchema[nodeId].outputSchema;
  const notSupportedIds = ['opsamplesrc01', 'opcsvurl01'];
  const isNotSupported = (id: string): boolean => notSupportedIds.includes(id);
  // TODO: HACKY FIX TO MAKE SOURCE OPERATORS WORK, MERGE PREVIEW+DAGIOSCHEMA into one api.
  const showDataPreview =
    (operatorCategory === OperatorCategory.Source && !isNotSupported(operatorId)) ||
    (nodeStatus === WorkflowRunOperatorStatus.Success && runId);
  const tabs =
    operatorCategory === OperatorCategory.Source
      ? [NodeTabs.Output]
      : operatorCategory === OperatorCategory.Sink
      ? [NodeTabs.Input]
      : [NodeTabs.Input, NodeTabs.Output];

  return (
    <Vertical h="100%">
      {tabs.map(tab => (
        <Vertical key={tab} h="100%">
          <Text variant="subTitle03" color="gray.8">
            {tab === NodeTabs.Input ? 'Input' : 'Output'}
          </Text>
          {showDataPreview ? (
            <DataPreviewContainer
              operatorCategory={operatorCategory}
              workflowId={workflowId}
              runId={runId ?? ''}
              nodeId={tab === NodeTabs.Input ? (prevNodeId as string) : nodeId}
              selectedColumns={tab === NodeTabs.Input ? selectedColumns : []}
              setSelectedColumns={tab === NodeTabs.Input ? setSelectedColumns : noop}
              allowColumnSelection={tab === NodeTabs.Input && allowColumnSelection}
              schema={tab === NodeTabs.Input ? inputSchema : outputSchema}
            />
          ) : (
            <NodeSchemaPreview
              schema={tab === NodeTabs.Input ? inputSchema : outputSchema}
              runStatus={runStatus}
              workflowId={workflowId}
              nodeId={nodeId}
              handleDebugClick={handleDebug}
              dagId={wfDetails?.dagId}
              selectedColumns={tab === NodeTabs.Input ? selectedColumns : []}
              setSelectedColumns={tab === NodeTabs.Input ? setSelectedColumns : noop}
              allowColumnSelection={tab === NodeTabs.Input && allowColumnSelection}
              top={tab === NodeTabs.Input ? '16%' : '61%'}
            />
          )}
        </Vertical>
      ))}
    </Vertical>
  );
};
