import {
  Box,
  Button,
  Card,
  Container,
  Horizontal,
  Text,
  Vertical,
} from '../../../design-system/v2';
import { ArtifactStateType } from '../../../generated/api';
import { useShowSideBar } from '../../../pages/home/WorkspaceShell';
import { GRADIENT_BACKGROUND } from '../../workspace/studio-home/workflow/WorkflowHomepage';
import { useWorkflowTemplateModal } from '../create/templates/useTemplateModal';
import { WorkflowsListContainer } from '../list/List.container';

export const getHeadingDescriptionFromState = (state: ArtifactStateType) => {
  switch (state) {
    case ArtifactStateType.Active:
      return {
        title: 'Published Workflows',
        description:
          'These are the workflows that have been finalized, reviewed, and published for active use.',
      };
    case ArtifactStateType.Archived:
      return {
        title: 'Archived Workflows',
        description:
          'These workflows are no longer active. They are stored in the archive for future reference,',
      };
    case ArtifactStateType.Draft:
    default:
      return {
        title: 'Drafts',
        description:
          'These workflows are still in development, undergoing refinement and adjustments before they are ready for publication.',
      };
  }
};

interface WorkflowsHomePageProps {
  state: ArtifactStateType;
}

export const WorkflowsListHomePage = ({ state }: WorkflowsHomePageProps) => {
  const { open: openTemplatesModal } = useWorkflowTemplateModal();
  useShowSideBar(true);
  const { title, description } = getHeadingDescriptionFromState(state);

  return (
    <Vertical h="100%" bg="white.0" spacing={0}>
      <Card hoverEffect={false} px="xxl" py="lg" sx={{ flexShrink: 0 }} bg={GRADIENT_BACKGROUND}>
        <Horizontal position="apart" noWrap>
          <Box>
            <Text variant="heading04" color="gray.8">
              {title}
            </Text>
            <Text variant="subTitle04" color="gray.6" my="xs">
              {description}
            </Text>
          </Box>
          <Button variant="primary" onClick={openTemplatesModal}>
            New workflow
          </Button>
        </Horizontal>
      </Card>
      <Box h="100%">
        <Container h="100%" size="xl" pt="xl">
          <WorkflowsListContainer state={state} />
        </Container>
      </Box>
    </Vertical>
  );
};
