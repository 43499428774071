import { IconPlugConnected } from '@tabler/icons-react';
import {
  Badge,
  Center,
  Flex,
  Horizontal,
  Skeleton,
  Text,
  Tooltip,
  Vertical,
} from '../../../../design-system/v2/';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { Workflow } from '../../../../generated/api';
import { useGetOperatorListQuery } from '../../../../queries/workflows/operators';
import { getOperatorIcon } from '../../util';

// Create a lookup map for operator icons
interface OperatorIcons {
  [key: string]: JSX.Element;
}

interface OperatorNames {
  [key: string]: string;
}

export const useOperatorIcons = () => {
  const { data: operators, isLoading, isError } = useGetOperatorListQuery();
  const operatorIcons: OperatorIcons = {};
  const operatorNames: OperatorNames = {};

  if (operators) {
    operators.forEach(operator => {
      operatorIcons[operator.operatorId] = getOperatorIcon(operator.iconUrl, 20);
      operatorNames[operator.operatorId] = operator.name;
    });
  }
  return { operatorIcons, operatorNames, isLoading, isError };
};

export const OperatorsRenderer = ({
  value: operators,
}: ICellRendererParams<Workflow, string[]>) => {
  const { operatorIcons, operatorNames, isLoading, isError } = useOperatorIcons();

  if (isLoading) {
    return (
      <Flex h="100%" w="100%" align="center">
        <Skeleton h={24} w={48} />
      </Flex>
    );
  }

  if (isError || !operators || operators.length === 0) {
    return (
      <Flex h="100%" w="100%" align="center">
        <Text color="gray.05"> -- -- </Text>
      </Flex>
    );
  }

  const maxIconsToShow = 3;
  const extraCount = operators.length > maxIconsToShow ? operators.length - maxIconsToShow : 0;

  const icons = operators.slice(0, maxIconsToShow).map((operatorId, index) => (
    <Tooltip key={index} label={operatorNames[operatorId] ?? operatorId} withinPortal>
      <Center>{operatorIcons[operatorId] ?? <IconPlugConnected size={20} />}</Center>
    </Tooltip>
  ));

  return (
    <Flex h="100%" w="100%" align="center">
      <Horizontal spacing="md">
        {icons}
        {extraCount > 0 && (
          <Tooltip
            label={
              <Vertical>
                {operators.slice(maxIconsToShow).map((operatorId, index) => (
                  <Text key={index}>{operatorNames[operatorId] ?? operatorId}</Text>
                ))}
              </Vertical>
            }
            withinPortal
          >
            <Badge color="gray.3" bg="gray.1" variant="outline">
              <Text color="gray.6" variant="small03">
                +{extraCount}
              </Text>
            </Badge>
          </Tooltip>
        )}
      </Horizontal>
    </Flex>
  );
};
