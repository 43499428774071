import { Horizontal } from '../../../../../design-system/v2';
import { GenAIProjectMetadataResponse } from '../../../../../generated/api';
import { AppProjectCard } from './AppProjectCard';

export interface AppProjectsListProps {
  projects: GenAIProjectMetadataResponse[];
  onProjectClick: (projectId: string) => () => void;
}

export const AppProjectsList = ({
  projects,
  onProjectClick: handleNavigateProjectDetails,
}: AppProjectsListProps): JSX.Element => (
  <Horizontal>
    {projects.map(project => (
      <AppProjectCard
        key={project.projectId}
        project={project}
        onProjectClick={handleNavigateProjectDetails}
      />
    ))}
  </Horizontal>
);
