import { TabularFilePreviewWrapper } from './file-preview';
import { PDFPreview } from './file-preview/PDFPreview';

interface FilePreviewOptions {
  setFileName: (e: React.ChangeEvent<any>) => void;
}

export const getFilePreviewComponentForFile = (
  file: File,
  onBackClick: () => void,
  options: FilePreviewOptions,
) => {
  if (file.type === 'text/csv') {
    return (
      <TabularFilePreviewWrapper
        file={file}
        fileName={file.name}
        onFileNameChange={options.setFileName}
        onBackClick={onBackClick}
      />
    );
  }

  if (file.type === 'application/pdf') {
    return <PDFPreview file={file} />;
  }
};
