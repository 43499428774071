import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { AccessDeniedPage } from '../pages/AccessDenied';
import { UnavailablePage } from '../pages/Unavailable';
import { GlobalHomePage } from '../pages/home/GlobalHome';
import { WorkspaceHomePage } from '../pages/home/Workspace';
import { WorkspaceContainerPage } from '../pages/home/WorkspaceContainer';
import { AppBuilderRouter } from './AppBuilderRouter';
import { AppStoreRouter } from './AppStoreRouter';
import { AuthRoute, renderAuthRoute } from './AuthRouter';
import { DataAnalyticsRouter } from './DataAnalyticsRouter';
import { DataLabelingRouter } from './DataLabelingRouter';
import { DataSourcesRouter } from './DataSourcesRouter';
import { DatasetsRouter } from './DatasetsRouter';
import { ExperimentsRouter } from './ExperimentsRouter';
import { ModelAppsRouter } from './ModelAppsRouter';
import { ModelsRouter } from './ModelsRouter';
import { ProjectsRouter } from './ProjectsRouter';
import { RecordingsRouter } from './RecordingsRouter';
import { SnippetsRouter } from './SnippetsRouter';
import { WorkflowsRouter } from './WorkflowsRouter';
import { AppRoutes, getDescendantRoute, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Settings = lazyWithRetry(() => import('../pages/Settings'));
const DocSearchPage = lazyWithRetry(() => import('../pages/DocSearch/DocSearch'));
const RunsPage = lazyWithRetry(() => import('../pages/Runs'));
const ModelStudioHomePage = lazyWithRetry(() => import('../pages/home/ModelStudioHome'));
const DataStudioHomePage = lazyWithRetry(() => import('../pages/home/DataStudioHome'));

const workspaceRoutes: RouteProps[] = [
  {
    path: getDescendantRoute(AppRoutes.EVALUATIONS),
    element: <RecordingsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.EXPERIMENTS),
    element: <ExperimentsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.DATA_SOURCES),
    element: <DataSourcesRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.DATASETS),
    element: <DatasetsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.DATA_LABELING),
    element: <DataLabelingRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.DATA_ANALYTICS),
    element: <DataAnalyticsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.MODELS),
    element: <ModelsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.MODEL_APPS),
    element: <ModelAppsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.PROJECTS),
    element: <ProjectsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.SNIPPETS),
    element: <SnippetsRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.WORKFLOWS_HOME),
    element: <WorkflowsRouter />,
  },
  {
    path: getRoute(AppRoutes.DATA_STUDIO_HOME),
    element: <DataStudioHomePage />,
  },
  {
    path: getRoute(AppRoutes.MODEL_STUDIO_HOME),
    element: <ModelStudioHomePage />,
  },
  {
    path: getRoute(AppRoutes.RUNS),
    element: <RunsPage />,
  },
  {
    path: getRoute(AppRoutes.SETTINGS),
    element: <Settings />,
  },
  {
    path: getDescendantRoute(AppRoutes.APP_STORE),
    element: <AppStoreRouter />,
  },
  {
    path: getDescendantRoute(AppRoutes.APP_BUILDER),
    element: <AppBuilderRouter />,
  },
  {
    path: getRoute(AppRoutes.DOCUMENT_SEARCH),
    element: <DocSearchPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: getRoute(AppRoutes.ACCESS_DENIED),
    element: <AccessDeniedPage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const WorkspaceRouter = (): JSX.Element => {
  const { featureProductRestructuring, featureGlobalHomepage } = useFlags();

  const rootEl = featureGlobalHomepage ? (
    <GlobalHomePage />
  ) : featureProductRestructuring ? (
    <Navigate to={getRoute(AppRoutes.DATA_STUDIO_HOME)} />
  ) : (
    <WorkspaceHomePage />
  );

  return (
    <Routes>
      <Route path="/" element={<AuthRoute element={<WorkspaceContainerPage />} />}>
        <Route index element={rootEl} />
        {workspaceRoutes.map(renderAuthRoute)}
      </Route>
    </Routes>
  );
};
