import { FileWithPath } from '@mantine/dropzone';
import first from 'lodash/first';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { Alert, Center, Loader } from '../../../../design-system/v2';
import { Table } from '../../../../design-system/v2/core/data-display/table';
import { ColDef } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { parseCSV } from '../../../../lib/fileparse';

const MAX_COLUMNS = 1000;

const getRowData = (parsedData: string[][], columns: ColDef[]): Record<string, string>[] =>
  parsedData.slice(1).map(parsedDataRow =>
    parsedDataRow.reduce<Record<string, string>>((prev, curr, idx) => {
      const colId = get(columns, `${idx}.colId`, `Unnamed:${idx}`);

      return {
        ...prev,
        [colId]: curr,
      };
    }, {}),
  );

interface FilePreviewProps {
  file: FileWithPath;
  delimiter: string;
}

export const FilePreview = ({ file, delimiter }: FilePreviewProps) => {
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [parsingError, setParsingError] = useState('');
  const [parsedData, setParsedData] = useState<string[][] | undefined>();

  useEffect(() => {
    setIsDataParsing(true);
    setParsingError('');

    if (file) {
      parseCSV(file, delimiter, { maxPreviewLines: 50 })
        .then(results => {
          const numCols = first(results)?.length ?? 0;
          if (numCols > MAX_COLUMNS) {
            setParsingError(
              `Preview is not available for files with more than ${MAX_COLUMNS} columns`,
            );
            setIsDataParsing(false);
            return;
          }

          setParsedData(results);
          setParsingError('');
          setIsDataParsing(false);
        })
        .catch(() => {
          const msg = 'Error fetching data preview. Please try again.';
          setParsingError(msg);
        });
    }
  }, [file, delimiter]);

  if (isDataParsing) {
    return (
      <Center mih={200} w="100%">
        <Loader text="Loading preview..." />
      </Center>
    );
  }

  if (parsingError) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">{parsingError}</Alert>
      </Center>
    );
  }

  if (!parsedData) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">Data preview not found. Must be an error</Alert>
      </Center>
    );
  }

  const columns: ColDef[] =
    first(parsedData)?.map((datum, idx) => {
      // Assign coldId in case column is empty string.
      // There could be empty string as header in CSVs but rows have data.
      const columnId = Boolean(datum) ? datum : `Unnamed: ${idx}`;
      return {
        colId: columnId,
        field: columnId,
        headerName: columnId,
        filter: false,
        sortable: false,
        minWidth: 100,
        flex: 1,
      };
    }) ?? [];

  const rowData = getRowData(parsedData, columns);

  return <Table rowData={rowData} columns={columns} suppressFieldDotNotation />;
};
