import { Box, Skeleton } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useMemo } from 'react';
import {
  DEFAULT_CITATION_STYLE,
  getCitationStyleDetails,
} from '../../../../../components/app-store/document-ai/commons/citations';
import { tryParseJSONObject } from '../../../../../components/app-store/document-ai/commons/utils';
import { ReferenceComponent } from '../../../../../components/app-store/document-ai/copy-edit/details/suggestions-panel/panels/ask-markov/CopyEditReferenceSection';
import {
  useGetAllCitationsQuery,
  useGetDocumentMetadataQuery,
} from '../../../../../queries/document-ai/copyEdit';
import { CITATION_FORMAT } from './constants';

export function getCitationsIdsArray(stringifiedCitations: string) {
  if (tryParseJSONObject(stringifiedCitations)) {
    return JSON.parse(stringifiedCitations);
  }
}

export const MarkovReferenceComponentContainer: React.FC<NodeViewProps> = ({
  editor,
  node,
  getPos,
  selected,
}) => {
  /**
   * node.attrs is set when doing addCitationToEditor. Please check implementation there
   * Currently node.attrs = {
   *  documentId: string;
   *  referenceIds: string[]
   * }
   */
  const citationIds: string[] = useMemo(
    () => getCitationsIdsArray(node.attrs.referenceIds) || [],
    [node.attrs.referenceIds],
  );
  const documentId: string = node.attrs.documentId ?? '';
  const isInlineCitation = node.attrs.format === CITATION_FORMAT.INLINE;

  const { data: documentMetadata } = useGetDocumentMetadataQuery(documentId);
  const { data: documentReferences, isLoading: isCitationsLoading } =
    useGetAllCitationsQuery(documentId);

  const citations = useMemo(
    () =>
      documentReferences?.citations.filter(({ citationId = '' }) =>
        citationIds.includes(citationId),
      ) ?? [],
    [documentReferences?.citations, citationIds],
  );

  const citationStyle = documentMetadata?.citationStyle ?? DEFAULT_CITATION_STYLE;

  const { getInlineCitationComponent } = getCitationStyleDetails(citationStyle);

  const handleCitationClick = () => {
    const position = getPos();
    editor.commands.setNodeSelection(position);
  };

  if (isCitationsLoading || !citations.length) {
    return (
      <NodeViewWrapper as="span">
        <Skeleton
          h={10}
          w={isInlineCitation ? 60 : 200}
          my={isInlineCitation ? 0 : 'xs'}
          sx={isInlineCitation ? { display: 'inline-block' } : {}}
        />
      </NodeViewWrapper>
    );
  }

  if (!isInlineCitation && citations && citations.length > 0) {
    return (
      <NodeViewWrapper as="span" draggable>
        <Box
          onClick={handleCitationClick}
          px="md"
          py="xs"
          sx={theme => ({
            cursor: 'pointer',
            userSelect: 'none',
            borderRadius: '4px',
            display: 'inline-block',
            borderStyle: 'dashed',
            borderColor: theme.colors.gray[3],
            backgroundColor: selected ? theme.fn.themeColor('blue.1') : '',
            ...theme.fn.hover({
              backgroundColor: selected
                ? theme.fn.themeColor('blue.1')
                : theme.fn.themeColor('gray.1'),
            }),
          })}
        >
          <ReferenceComponent referenceObj={citations[0]} referenceStyle={citationStyle} />
        </Box>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="span" draggable>
      {getInlineCitationComponent(citations)}
    </NodeViewWrapper>
  );
};
