import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAppVariantFromAppId } from '../../components/app-store/document-ai/commons/AppFrame';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AppIdType,
  BodyRegisterAppDocumentV3,
  CreateGenAIProjectRequest,
  DocumentDownloadFormats,
  DocumentDownloadStatusType,
  InviteGenAIProjectRequest,
  RemoveInviteGenAIProjectRequest,
  UpdateGenAIProjectRequest,
} from '../../generated/api';
import { appStoreApi } from '../../lib/api/reno';

export const documentAIKeys = {
  all: ['app-store-apps'] as const,
  appProjectList: (workspaceId: string, appId: string) =>
    [...documentAIKeys.all, workspaceId, 'project', appId] as const,
  appProjectDocsList: (workspaceId: string, appId: string, projectId: string) =>
    [...documentAIKeys.appProjectList(workspaceId, appId), projectId] as const,
  asyncDownloadStatus: (workspaceId: string, appId: string, documentId: string, taskId: string) =>
    [...documentAIKeys.all, 'download-status', workspaceId, appId, documentId, taskId] as const,
  latestDocumentDownload: (workspaceId: string, appId: string, documentId: string) =>
    [...documentAIKeys.all, 'latest-doc-download', workspaceId, appId, documentId] as const,
};

export const useDocumentDownloadMutation = (appId: AppIdType, documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((downloadFormat: DocumentDownloadFormats) =>
    appStoreApi.getDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, {
      // TODO: Create a background task to download docx
      timeout: 2 * 60 * 1000, // 2 Min
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    }),
  );
};

export const useTriggerAsyncDocumentDownloadMutation = (
  appId: AppIdType,
  documentId: string,
  downloadFormat = DocumentDownloadFormats.Docx,
) => {
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: () =>
      appStoreApi.triggerAsyncDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat),
  });
};

export const useAsyncDownloadStatusQuery = (
  appId: AppIdType,
  documentId: string,
  taskId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryFn: () => appStoreApi.getDocumentDownloadStatusV1(workspaceId, appId, documentId, taskId),
    queryKey: documentAIKeys.asyncDownloadStatus(workspaceId, appId, documentId, taskId),
    select: res => res.data,
    refetchInterval: data =>
      data?.status === DocumentDownloadStatusType.Processing ? 2000 : false,
    enabled: Boolean(workspaceId && appId && documentId && taskId),
  });
};

export const useLatestDocumentDownloadQuery = (appId: AppIdType, documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery({
    queryKey: documentAIKeys.latestDocumentDownload(workspaceId, appId, documentId),
    queryFn: () => appStoreApi.getLatestDocumentDownloadV1(workspaceId, appId, documentId),
    select: res => res.data,
    staleTime: 0,
    enabled: Boolean(workspaceId && appId && documentId),
  });
};

export const useGetAppProjectsListQuery = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useQuery(
    documentAIKeys.appProjectList(workspaceId, appId),
    () => appStoreApi.fetchGenAIProjectsV1(workspaceId, appIdType),
    {
      select: data => data.data.projects,
      enabled: Boolean(workspaceId),
    },
  );
};

export const useCreateAppProjectMutation = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: CreateGenAIProjectRequest) =>
      appStoreApi.createGenAIProjectV1(workspaceId, appIdType, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
      },
    },
  );
};

export const useUpdateAppProjectMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation({
    mutationFn: (req: UpdateGenAIProjectRequest) =>
      appStoreApi.updateGenAIProjectV1(workspaceId, appIdType, projectId, req),
    onSuccess: () => {
      queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
    },
  });
};

export const useDeleteAppProjectMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation({
    mutationFn: () => appStoreApi.deleteGenAIProjectV1(workspaceId, appIdType, projectId),
    onSuccess: () => {
      queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
    },
  });
};

export const useGetAppProjectDocumentListQuery = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useQuery(
    documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
    () => appStoreApi.getAppDocumentsListV2(workspaceId, appIdType, projectId),
    {
      select: data => data.data,
    },
  );
};

export interface DocumentRegistrationMutationProps {
  documentId: string;
  requestBody?: BodyRegisterAppDocumentV3;
}

export const useAppProjectRegisterDocMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: DocumentRegistrationMutationProps) =>
      appStoreApi.registerAppDocumentV3(
        workspaceId,
        req.documentId,
        projectId,
        appIdType,
        req.requestBody,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
        );
      },
    },
  );
};

export const useAppProjectInviteUserMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: InviteGenAIProjectRequest) =>
      appStoreApi.inviteGenAIProjectV1(workspaceId, appIdType, projectId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appIdType, projectId),
        );
      },
    },
  );
};

export const useAppProjectRevokeUserMutation = (appId: string, projectId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const appIdType = getAppVariantFromAppId(appId) ?? AppIdType.CopyEdit;

  return useMutation(
    (req: RemoveInviteGenAIProjectRequest) =>
      appStoreApi.removeUserFromGenAIProjectV1(workspaceId, appIdType, projectId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
        queryClient.invalidateQueries(
          documentAIKeys.appProjectDocsList(workspaceId, appIdType, projectId),
        );
      },
    },
  );
};

export const useDeleteDocumentMutation = (appId: string, projectId: string, documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => appStoreApi.deleteDocumentV1(workspaceId, documentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(documentAIKeys.appProjectList(workspaceId, appId));
      queryClient.invalidateQueries(
        documentAIKeys.appProjectDocsList(workspaceId, appId, projectId),
      );
    },
  });
};
