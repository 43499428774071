import { IconPlugConnected } from '@tabler/icons-react';
import { MarkerType } from 'reactflow';
import { Flow, Image } from '../../design-system/v2';
import { grays } from '../../design-system/v2/theme/colors/colorList';
import { WorkflowEdge, WorkflowNode } from './nodes/utils';

export const defaultNodeProps: Partial<WorkflowNode> = {
  sourcePosition: Flow.Position.Right,
  targetPosition: Flow.Position.Left,
};

export const defaultEdgeProps: Partial<WorkflowEdge> = {
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: grays.gray6,
  },
  style: {
    strokeWidth: 2,
    stroke: grays.gray6,
  },
};

export const getOperatorIcon = (iconUrl: string, size = 32) => {
  if (iconUrl.length === 0) {
    return <IconPlugConnected size={size} />;
  }

  return <Image src={iconUrl} width={size} height={size} />;
};
