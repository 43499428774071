import { CitationStyleGuideType, StyleGuideType } from '../../../../../generated/api';

export const StyleGuideMap = {
  [StyleGuideType.None]: 'None',
  [StyleGuideType.Cmos]: 'CMOS (Chicago Manual Of Style)',
  // [StyleGuideType.Ada]: 'APA (American Psychological Association)',
};

export const CitationStyleGuideMap = {
  [CitationStyleGuideType.Cmos]: 'CMOS (Chicago Manual Of Style)',
  [CitationStyleGuideType.Apa]: 'APA (American Psychological Association)',
};
