import dayjs from 'dayjs';
import {
  AuthorInformationModel,
  Citation,
  CitationStyleGuideType,
  CitationType,
  CopyEditCitation,
  DateModel,
} from '../../../../../generated/api';
import {
  Author,
  BaseReference,
  Identifier,
  JournalInformation,
  LLMAnswerAndReferences,
  MarkovPanelReference,
  OthersInformation,
  PublishingInformation,
  WebsiteInformation,
} from '../../../../common/chat-with-data/chat/answers/factory';
import {
  getInlineCitationComponentAPA,
  getInlineCitationTextAPA,
  getListCitationTextAPA,
} from './apa';
import {
  getInlineCitationComponentCMOS,
  getInlineCitationTextCMOS,
  getListCitationTextCMOS,
} from './cmos';
import { DEFAULT_CITATION_STYLE, DEFAULT_DATE_MODEL, MISSING_DATE_TEXT } from './constants';

const doiRegex = /^https:\/\/doi\.org\/10\.\d{4,9}\/[-._;()/:A-Z0-9]+$/i;
export const isValidDOI = (url: string): boolean => {
  if (!url) {
    return true;
  }
  return doiRegex.test(url);
};

interface CitationStyleGuideDetails {
  getInlineCitationComponent: (citation: CopyEditCitation[]) => JSX.Element;
  getInlineCitationText: (citation: Citation) => string;
  getListCitationText: (citation: Citation) => JSX.Element;
}

// Extend this type and map below as we add support for other citation styles
type SupportedCitationStyleGuide = CitationStyleGuideType.Cmos | CitationStyleGuideType.Apa;

const citationStyleGuideDetailsMap: Record<SupportedCitationStyleGuide, CitationStyleGuideDetails> =
  {
    [CitationStyleGuideType.Cmos]: {
      getInlineCitationComponent: getInlineCitationComponentCMOS,
      getInlineCitationText: getInlineCitationTextCMOS,
      getListCitationText: getListCitationTextCMOS,
    },
    [CitationStyleGuideType.Apa]: {
      getInlineCitationComponent: getInlineCitationComponentAPA,
      getInlineCitationText: getInlineCitationTextAPA,
      getListCitationText: getListCitationTextAPA,
    },
  };

export const getCitationStyleDetails = (refStyle: CitationStyleGuideType) => {
  // Use CMOS by default if None is specified
  const appliedRefStyle =
    refStyle === CitationStyleGuideType.None ? DEFAULT_CITATION_STYLE : refStyle;
  if (!Object.hasOwn(citationStyleGuideDetailsMap, appliedRefStyle)) {
    throw new Error('Configuration not found for citation style guide ' + appliedRefStyle);
  }
  return citationStyleGuideDetailsMap[appliedRefStyle as SupportedCitationStyleGuide];
};

const citationLabelMap = {
  [CitationType.JournalArticle]: 'Journal Article',
  [CitationType.Website]: 'Website',
  [CitationType.Others]: 'Other',
};

export function getSingleInlineAuthor(author: AuthorInformationModel): string {
  if (author.lastName) {
    return author.lastName;
  } else if (author.firstName) {
    return author.firstName;
  }
  return '';
}

export function getMultipleInlineAuthors(authors: AuthorInformationModel[]): string {
  const authorNames = authors.map(author => getSingleInlineAuthor(author));
  return `${authorNames.slice(0, -1).join(', ')}, & ${authorNames[authorNames.length - 1]}`;
}

export function getSingleListAuthorText(
  author: AuthorInformationModel,
  delimiter: string,
  { reverse = true, shortenFirstName = false } = {},
): string {
  const firstName = shortenFirstName
    ? author.firstName
        ?.split(' ')
        .map(name => `${name.charAt(0)}.`)
        .join(' ')
    : author.firstName;

  if (reverse) {
    if (author.lastName && firstName) {
      return `${author.lastName}${delimiter}${firstName}`;
    } else if (author.lastName) {
      return `${author.lastName}${firstName}`;
    } else if (author.firstName) {
      return `${author.firstName}`;
    }
  } else {
    if (author.lastName && firstName) {
      return `${firstName}${delimiter}${author.lastName}`;
    } else if (author.lastName) {
      return `${author.lastName}${firstName}`;
    } else if (author.firstName) {
      return `${author.firstName}`;
    }
  }
  return '';
}

export function getMultipleListAuthorsText(
  authors: AuthorInformationModel[],
  infix: string,
  reverse: boolean,
  shortenFirstName: boolean,
): string {
  const firstAuthorText = getSingleListAuthorText(authors[0], ', ', {
    shortenFirstName: shortenFirstName,
  });
  const spacedAuthorNames = authors.slice(1).map(author =>
    getSingleListAuthorText(author, ' ', {
      reverse: reverse,
      shortenFirstName: shortenFirstName,
    }),
  );

  if (authors.length === 2) {
    return `${firstAuthorText}, ${infix} ${spacedAuthorNames[0]}`;
  } else {
    return `${firstAuthorText}, ${spacedAuthorNames.slice(0, -1).join(', ')}, ${infix} ${
      spacedAuthorNames[spacedAuthorNames.length - 1]
    }`;
  }
}

export function _getStrFromDate(date: DateModel): string[] {
  let monthStr = '';
  if (date.month && date.month !== MISSING_DATE_TEXT) {
    const monthObj =
      date.month.length > 3
        ? dayjs()
            .month(parseInt(date.month, 10) - 1)
            .format('MMMM') // Full month name
        : dayjs()
            .month(parseInt(date.month, 10) - 1)
            .format('MMM'); // Abbreviated month name
    monthStr = monthObj;
  }

  const dayStr = date.day ? date.day.toString() : '';
  const yearStr = date.year || '';

  return [dayStr, monthStr, yearStr];
}

export function getFormattedPublishedDate(citationObject: Citation): string {
  const [dayStr, monthStr, yearStr] = _getStrFromDate(
    citationObject.publishedDate || DEFAULT_DATE_MODEL,
  );

  if (
    monthStr !== MISSING_DATE_TEXT &&
    dayStr !== MISSING_DATE_TEXT &&
    yearStr !== MISSING_DATE_TEXT
  ) {
    return ` ${monthStr} ${dayStr}, ${yearStr}.`;
  } else if (monthStr !== MISSING_DATE_TEXT && yearStr !== MISSING_DATE_TEXT) {
    return ` ${monthStr} ${yearStr}.`;
  } else if (yearStr !== MISSING_DATE_TEXT) {
    return ` ${yearStr}.`;
  } else {
    return '';
  }
}

export function getWebsiteName(citationObject: Citation): string {
  if (citationObject.websiteCitation) {
    return `${citationObject.websiteCitation.websiteName}.`;
  }
  return '';
}

export function getResourceName(citationObject: Citation): string {
  if (citationObject.othersCitation) {
    return `${citationObject.othersCitation.resourceName}.`;
  }
  return '';
}

export function getAccessedDate(citationObject: Citation, isOthers = true): string {
  const othersAccessedDate = citationObject.othersCitation?.accessedDate || DEFAULT_DATE_MODEL;
  const websiteAccessedDate = citationObject.websiteCitation?.accessedDate || DEFAULT_DATE_MODEL;

  const accessedDate = isOthers ? othersAccessedDate : websiteAccessedDate;
  const [dayStr, monthStr, yearStr] = _getStrFromDate(accessedDate);

  if (
    monthStr !== MISSING_DATE_TEXT &&
    dayStr !== MISSING_DATE_TEXT &&
    yearStr !== MISSING_DATE_TEXT
  ) {
    return `Accessed ${monthStr} ${dayStr}, ${yearStr}. `;
  } else if (monthStr !== MISSING_DATE_TEXT && yearStr !== MISSING_DATE_TEXT) {
    return `Accessed ${monthStr} ${yearStr}. `;
  } else if (yearStr !== MISSING_DATE_TEXT) {
    return ` Accessed ${yearStr}. `;
  } else {
    return '';
  }
}

export const getCitationPanelText = (
  citation: Citation,
  citationStyle: CitationStyleGuideType = DEFAULT_CITATION_STYLE,
): string => {
  const { getInlineCitationText } = getCitationStyleDetails(citationStyle);
  const label = citationLabelMap[citation.citationType] ?? 'Unknown';
  return `${label}: ${getInlineCitationText(citation)}`;
};

const isAuthor = (obj: any): obj is Author =>
  (typeof obj.first_name === 'string' || obj.first_name === undefined) &&
  (typeof obj.last_name === 'string' || obj.last_name === undefined);

const isIdentifier = (obj: any): obj is Identifier =>
  typeof obj.url === 'string' && (typeof obj.doi === 'string' || obj.doi === null);

const isPublishingInformation = (obj: any): obj is PublishingInformation =>
  typeof obj.title === 'string' && typeof obj.published_date === 'string';

const isJournalInformation = (obj: any): obj is JournalInformation =>
  typeof obj.publication_volume === 'string' &&
  typeof obj.publication_issue === 'string' &&
  typeof obj.total_pages === 'string' &&
  isPublishingInformation(obj);

const isWebsiteInformation = (obj: any): obj is WebsiteInformation => isPublishingInformation(obj);

const isOthersInformation = (obj: any): obj is OthersInformation => isPublishingInformation(obj);

const isBaseCitation = (obj: any): obj is BaseReference =>
  typeof obj.title === 'string' &&
  typeof obj.accessed_date === 'string' &&
  Array.isArray(obj.authors) &&
  obj.authors.every(isAuthor) &&
  isIdentifier(obj.identifier);

const isMarkovPanelCitation = (obj: any): obj is MarkovPanelReference =>
  typeof obj.citation_type === 'string' &&
  (obj.journal_citation === null || isJournalInformation(obj.journal_citation)) &&
  (obj.website_citation === null || isWebsiteInformation(obj.website_citation)) &&
  (obj.others_citation === null || isOthersInformation(obj.others_citation)) &&
  isBaseCitation(obj);

export const isLLMAnswerAndCitations = (obj: any): obj is LLMAnswerAndReferences =>
  typeof obj.answer === 'string' &&
  Array.isArray(obj.citations) &&
  obj.citations.every(isMarkovPanelCitation);
