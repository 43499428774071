import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  ExportTemplateRequest,
  FilePreview,
  NodeOutput,
  OperatorCategory,
  WorkflowNodeSchema,
  WorkflowNodeSchemaResponse,
} from '../../generated/api';
import { operatorsApi, workflowApi } from '../../lib/api';
import { HTTPError } from '../../lib/api/api';

export const operatorKeys = {
  all: ['workflow-operators'],
  list: (workspaceId: string) => [...operatorKeys.all, 'list', workspaceId],
  detail: (workspaceId: string, operatorId: string) => [
    ...operatorKeys.all,
    'detail',
    workspaceId,
    operatorId,
  ],
  getRunLogs: (workspaceId: string, workflowId: string, runId: string) => [
    ...operatorKeys.all,
    'get-run-logs',
    workspaceId,
    workflowId,
    runId,
  ],
  getNodeRunLogs: (workspaceId: string, workflowId: string, runId: string, nodeId: string) => [
    ...operatorKeys.all,
    'get-run-logs',
    workspaceId,
    workflowId,
    runId,
    nodeId,
  ],
  getDagIOSchema: (workspaceId: string, workflowId: string) => [
    ...operatorKeys.all,
    'get-run-preview',
    workspaceId,
    workflowId,
  ],
  getWorkflowDagNodesSchema: (workspaceId: string, workflowId: string, nodeId: string) => [
    ...operatorKeys.all,
    'get-run-preview',
    workspaceId,
    workflowId,
    nodeId,
  ],
  getNodeRunPreview: (
    workspaceId: string,
    workflowId: string,
    runId: string,
    nodeId: string,
    operatorCategory: string,
  ) => [
    ...operatorKeys.all,
    'get-run-preview',
    workspaceId,
    workflowId,
    runId,
    nodeId,
    operatorCategory,
  ],
};

export const templateKeys = {
  all: 'workflow-templates',
  list: (workspaceId: string) => [templateKeys.all, 'list', workspaceId],
};
export const useGetOperatorListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(operatorKeys.list(workspaceId), () => operatorsApi.listOperatorsV1(workspaceId), {
    enabled: !!workspaceId,
    select: data => data.data.operators,
    staleTime: Infinity,
  });
};

export const useGetOperatorDetailsQuery = (operatorId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    operatorKeys.detail(workspaceId, operatorId),
    () => operatorsApi.getOperatorDetailsV1(workspaceId, operatorId),
    {
      enabled: !!workspaceId && !!operatorId,
      select: data => data.data,
    },
  );
};

export const useNodeRunLogsQuery = (workflowId: string, runId: string, nodeId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    operatorKeys.getNodeRunLogs(workspaceId, workflowId, runId, nodeId),
    () => workflowApi.getWorkflowRunNodeLogFileV1(workspaceId, workflowId, runId, nodeId),
    {
      enabled: !!workspaceId && !!workflowId && !!runId,
      select: data => data.data as any,
    },
  );
};

export const useGetTemplatesListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(templateKeys.list(workspaceId), () => operatorsApi.listTemplatesV1(workspaceId), {
    enabled: !!workspaceId,
    select: data => data.data.templates,
    staleTime: Infinity,
  });
};

export const useNodeDataPreviewQuery = (
  workflowId: string,
  runId: string,
  nodeId: string,
  operatorCategory: OperatorCategory | '',
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<NodeOutput>, AxiosError<any>, FilePreview>(
    operatorKeys.getNodeRunPreview(workspaceId, workflowId, runId, nodeId, operatorCategory),
    () =>
      workflowApi.getWorkflowRunNodeOutputV1(
        workspaceId,
        workflowId,
        runId,
        nodeId,
        operatorCategory,
      ),
    {
      enabled: !!workspaceId && !!workflowId,
      select: data => data.data.data,
    },
  );
};

export const useDagIOSchemaQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    operatorKeys.getDagIOSchema(workspaceId, workflowId),
    () => workflowApi.getWorkflowDagNodesSchemaV1(workspaceId, workflowId),

    {
      enabled: !!workspaceId && !!workflowId,
      select: data => data.data.response,
      cacheTime: 0,
      staleTime: 0,
    },
  );
};

export const useGetWorkflowDagNodesSchemaV2Query = (workflowId: string, nodeId?: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<WorkflowNodeSchemaResponse>,
    AxiosError<HTTPError>,
    WorkflowNodeSchema
  >(
    operatorKeys.getWorkflowDagNodesSchema(workspaceId, workflowId, nodeId ?? ''),
    () => workflowApi.getWorkflowDagNodesSchemaV2(workspaceId, workflowId, nodeId),
    {
      enabled: Boolean(workspaceId && workflowId),
      select: data => data.data.response,
    },
  );
};

export const useExportTemplateMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation(
    (request: ExportTemplateRequest) =>
      operatorsApi.saveAsWorkflowTemplatesV1(workspaceId, request),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: templateKeys.list(workspaceId) }),
    },
  );
};

export const useDeleteTemplateMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation(
    (templateId: string) => operatorsApi.deleteWorkflowTemplatesV1(workspaceId, templateId),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: templateKeys.list(workspaceId) }),
    },
  );
};

export const useExportAsJsonMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation((request: ExportTemplateRequest) =>
    operatorsApi.exportAsWorkflowTemplatesV1(workspaceId, request),
  );
};
