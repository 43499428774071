import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { StorageType } from '../../generated/api';
import { connectorsApi } from '../../lib/api';

const queryKeys = {
  idpExchange: (workspaceId: string, code: string) => [workspaceId, code],
};

// Note: Static value, not required in env
const FULL_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive';

export const useGetConsentUrl = () => {
  const { workspaceId } = useAppMetadata();
  return useMutation(async () => {
    const resp = await connectorsApi.consentUrlV1(workspaceId, 'google', FULL_DRIVE_SCOPE);

    return resp.data.url;
  });
};

export const useIDPExchangeForGoogleOAuth = (workspaceId: string, code: string) =>
  useQuery(
    queryKeys.idpExchange(workspaceId, code),
    () => {
      const redirectUri = `${window.origin}/google/oauth`;
      return connectorsApi.iDPExchangeAuthCodeV2(workspaceId, {
        authCode: code,
        connectorType: StorageType.GoogleDrive,
        connectorName: '',
        redirectUri: redirectUri,
      });
    },
    {
      select: data => data.data,
    },
  );
