import { css } from '@emotion/react';
import { v4 as uuid } from 'uuid';
import {
  Edge as ReactflowEdge,
  Node as ReactflowNode,
  useMarkovTheme,
} from '../../../design-system/v2';

import {
  NodeData as ApiNodeData,
  Node,
  OperatorCategory,
  OperatorModel,
  WorkflowRunOperatorStatus,
} from '../../../generated/api';
import {
  AllParameterTypeProps,
  FormDataTypes,
} from '../create/workflow-builder/operator-parameter-form/util';
import { NODE_SIZE } from './factory';

//no special characters because of kube-flow restrictions
const getId = () => `node${uuid().substring(0, 4)}`;

export interface XYPosition {
  x: number;
  y: number;
}

export type NodeParameters = AllParameterTypeProps;

export interface NodeData extends Omit<ApiNodeData, 'configuration'> {
  configuration?: Record<string, FormDataTypes>;
}

export enum EdgeType {
  DataFrame = 'DataFrame',
  // It could be ModelOutput etc, that we have to figure out.
  // Inference output would be a data-frame only, we have to
  // look at training example.
  Model = 'Model',
}

export interface EdgeData {
  isHovered: boolean;
}

export type WorkflowNode = ReactflowNode<NodeData>;
export type WorkflowEdge = ReactflowEdge<EdgeData>;

export const createWorkflowNode = (
  operatorId: string,
  nodeCategory: OperatorCategory,
  position: XYPosition,
  name?: string,
): WorkflowNode => {
  const newNode: WorkflowNode = {
    id: getId(),
    position,
    type: nodeCategory,

    data: {
      id: operatorId,
      configuration: {},
    },
  };

  return newNode;
};

export const transformNodeToWorkflowNode = (
  node: Node,
  operators: OperatorModel[],
): WorkflowNode => {
  // Kubeflow is making node ids lowercase. so matching is required like this
  const operator = operators.find(op => op.operatorId.toLowerCase() === node.data.id.toLowerCase());

  const newNode: WorkflowNode = {
    id: node.id,
    position: node.uiData.position,
    type: operator?.category,
    data: {
      id: node.data.id,
      configuration: { ...node.data.configuration },
    },
  };

  return newNode;
};

export const transformWorkflowNodeToNode = (node: WorkflowNode): Node => {
  const newNode: Node = {
    id: node.id,
    data: {
      id: node.data.id,
      configuration: { ...node.data.configuration },
    },
    uiData: {
      position: node.position,
      additional: {},
    },
  };

  return newNode;
};

export const MAX_ALLOWED_CONNECTIONS_PER_HANDLE = 12;

export const getColorsFromStatus = (
  status: WorkflowRunOperatorStatus,
  operatorType: OperatorCategory,
) => {
  const theme = useMarkovTheme();
  switch (status) {
    case WorkflowRunOperatorStatus.Success:
      return {
        bgColor: 'green.0',
        borderColor: theme.colors.green[6],
      };
    case WorkflowRunOperatorStatus.Failed:
      return {
        bgColor: 'red.0',
        borderColor: theme.colors.red[6],
      };
    case WorkflowRunOperatorStatus.Terminated:
      return {
        bgColor: 'gray.1',
        borderColor: theme.colors.gray[6],
      };
    case WorkflowRunOperatorStatus.Running:
      return {
        bgColor: 'yellow.0',
        borderColor: theme.colors.yellow[6],
        revolvingBorder: css`
          position: relative;
          width: ${NODE_SIZE + 10}px;
          height: ${NODE_SIZE + 10}px;
          overflow: hidden;
          border-radius: ${operatorType === OperatorCategory.Process ? '12px' : '50%'};
          z-index: 0;
          &::before {
            content: '';
            position: absolute;
            top: -50%;
            left: -50%;
            right: -50%;
            bottom: -50%;
            background: linear-gradient(0deg, #fff1d6 0%, #ffad0a 100%);
            clip-path: polygon(0 0, 0 100%, 100% 0);
            animation: rotate 2s linear infinite;
          }
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `,
      };
    case WorkflowRunOperatorStatus.NotStarted:
    default:
      return {
        bgColor: 'white.0',
        borderColor: theme.colors.gray[3],
      };
  }
};
