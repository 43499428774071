import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { WorkflowsListHomePage } from '../components/workflows/home/WorkflowsListHomePage';
import { TemplatesWrapper } from '../components/workspace/studio-home/workflow/Templates.wrapper';
import { ArtifactStateType } from '../generated/api';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Workflows = lazyWithRetry(() => import('../pages/Workflows/Workflows'));
const WorkflowCreate = lazyWithRetry(() => import('../pages/Workflows/WorkflowCreate'));
const WorkflowDetail = lazyWithRetry(() => import('../pages/Workflows/WorkflowDetail'));
const WorkflowRunDetails = lazyWithRetry(() => import('../pages/Workflows/WorkflowRunDetails'));

const workflowDetail = getRoute(AppRoutes.WORKFLOW_DETAIL);

const workflowRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Workflows />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_TEMPLATES),
    element: <TemplatesWrapper />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_DRAFT),
    element: <WorkflowsListHomePage state={ArtifactStateType.Draft} />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_PUBLISHED),
    element: <WorkflowsListHomePage state={ArtifactStateType.Active} />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_ARCHIVED),
    element: <WorkflowsListHomePage state={ArtifactStateType.Archived} />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOW_ADD),
    element: <WorkflowCreate />,
  },
  {
    path: workflowDetail,
    element: <WorkflowDetail />,
  },
  {
    path: `${workflowDetail}/${getRoute(AppRoutes.WORKFLOW_RUNS)}`,
    element: <WorkflowDetail />,
  },
  {
    path: `${workflowDetail}/${getRoute(AppRoutes.WORKFLOW_RUNS)}/${getRoute(
      AppRoutes.WORKFLOW_RUN_DETAIL,
    )}`,
    element: <WorkflowRunDetails />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const WorkflowsRouter = (): JSX.Element => (
  <Routes>{workflowRoutes.map(renderAuthRoute)}</Routes>
);
