import { useState } from 'react';
import { Box, Container, Text } from '../../../../design-system/v2';
import { useShowSideBar } from '../../../../pages/home/WorkspaceShell';
import { Searchbox } from '../../../common/search-box/Searchbox';
import { GRADIENT_BACKGROUND } from './WorkflowHomepage';
import { WorkflowTemplateSection } from './WorkflowTemplateSection';

export const TemplatesWrapper = () => {
  useShowSideBar(true);
  const [searchText, setSearchText] = useState('');

  return (
    <Box w="100%" mih="100%" bg={GRADIENT_BACKGROUND}>
      <Container py="lg" size="xl">
        <Text variant="heading04" color="gray.8">
          All templates
        </Text>
        <Text variant="subTitle04" color="gray.6" my="xs">
          Discover our collection of pre-built templates are crafted to meet common business needs,
          making it easy for you to implement effective solutions without starting from scratch.
        </Text>
        <Searchbox onSearch={setSearchText} width="100%" margin="32px 0 24px" />
        <WorkflowTemplateSection searchText={searchText} />
      </Container>
    </Box>
  );
};
