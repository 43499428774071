import {
  ControlProps,
  EnumOption,
  OwnPropsOfEnum,
  RankedTester,
  isEnumControl,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import { useState } from 'react';
import { Select, Text } from '../../../../../design-system/v2';

export const SelectControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  enabled,
  visible,
  data,
  schema,
  options = [],
  errors,
  config,
}: ControlProps & OwnPropsOfEnum) => {
  const [created, setCreated] = useState<EnumOption[]>([]);

  if (!visible) {
    return null;
  }

  const onChange = (value: string) => {
    handleChange(path, value);
  };

  const uiSchemaOptions = uischema.options;
  let dropdownOptions = uiSchemaOptions?.dropdownOptions ?? {};

  if (dropdownOptions.creatable) {
    const handleCreateInputChange = (query: string) => {
      const item = { value: query, label: query };
      setCreated(prev => [...prev, { value: query, label: query }]);

      return item;
    };

    dropdownOptions = {
      ...dropdownOptions,
      getCreateLabel: (query: string) => `+ Create ${query}`,
      onCreate: handleCreateInputChange,
    };
  }

  const isViewOnlyForm = config.viewOnly;

  return (
    <Select
      ariaLabel={label || uischema.scope}
      label={
        <Text span variant="subTitle02">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      placeholder={uiSchemaOptions?.placeholder}
      options={[...options, ...created]}
      onChange={onChange}
      value={data}
      defaultValue={schema.default}
      {...dropdownOptions}
      error={config.isFormDirty ? errors : undefined}
      withinPortal
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      withAsterisk={required}
    />
  );
};

export const selectControlTester: RankedTester = rankWith(5, isEnumControl);

export const SelectControl = withJsonFormsEnumProps(SelectControlComponent);
