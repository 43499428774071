import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  CloneWorkflowRequest,
  DeleteWorkflowsRequest,
  SortOrder,
  UpdateWorkflowArtifactStateRequest,
} from '../../../generated/api';
import { workflowApi } from '../../../lib/api';

export const workflowsQueryKeys = {
  all: ['workflows'] as const,
  list: (
    workspaceId: string,
    filters?: object[],
    start?: number,
    end?: number,
    sortKey?: string,
    sortOrder?: SortOrder,
    searchQuery?: string,
  ) =>
    [
      ...workflowsQueryKeys.all,
      'list',
      workspaceId,
      start,
      end,
      sortKey,
      sortOrder,
      filters,
      searchQuery,
    ] as const,
  listForRuns: (
    workspaceId: string,
    workflowId: string,
    start?: number,
    filters?: BaseAPIFilter[],
  ) => [...workflowsQueryKeys.all, 'list-runs', workspaceId, workflowId, start, filters] as const,
  listAllRuns: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'list-runs', workspaceId, workflowId] as const,
  detail: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'detail', workspaceId, workflowId] as const,
  dag: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'get-workflow-dag', workspaceId, workflowId] as const,
  runsDetail: (workspaceId: string, workflowId: string, workflowRunId: string) => [
    ...workflowsQueryKeys.all,
    'runs-detail',
    workspaceId,
    workflowId,
    workflowRunId,
  ],
  emailTrigger: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'email-trigger', workspaceId, workflowId] as const,
};

export const useGetWorkflowsQuery = ({
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
  searchQuery = '',
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
  searchQuery?: string;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.list(workspaceId, filters, start, limit, sortKey, sortOrder, searchQuery),
    // Implement Filters
    () =>
      workflowApi.listWorkflowsV1(
        workspaceId,
        {
          start,
          limit,
          sortKey,
          sortOrder,
          filters,
        },
        searchQuery,
      ),
    {
      select: res => {
        const response = res.data.response;
        const transformedData = response.map(item => ({
          ...item,
          name: item.name.length === 0 ? 'Untitled' : item.name,
        }));
        return {
          ...res.data,
          response: transformedData,
        };
      },
      staleTime: 30 * 1000,
      keepPreviousData: true,
    },
  );
};

export const useWorkflowStateUpdateMutation = (
  workflowArtifactState: UpdateWorkflowArtifactStateRequest,
  OnWorkflowUpdateSuccess: () => void,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.workflowStateChangeV1(workspaceId, workflowArtifactState), {
    onSuccess: () => {
      OnWorkflowUpdateSuccess();
      queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all });
    },
  });
};

export const useWorkflowDeleteMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (workflow_ids: DeleteWorkflowsRequest) =>
      workflowApi.deleteWorkflowsV1(workspaceId, workflow_ids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all });
      },
    },
  );
};

export const useGetWorkflowRunsQuery = ({
  workflowId = '',
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
}: {
  workflowId: string;
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.listForRuns(workspaceId, workflowId, start, filters),
    () =>
      workflowApi.listWorkflowRunsV1(workspaceId, workflowId, {
        start,
        limit,
        sortKey,
        sortOrder,
        filters,
      }),
    {
      // Narrow type of nodeWiseOutput from `object`
      select: res => ({
        ...res.data,
        response: res.data.response.map(run => ({
          ...run,
          nodeWiseOutput: run.nodeWiseOutput,
        })),
      }),
      staleTime: 30 * 1000,
      keepPreviousData: true,
    },
  );
};

export const useWorkflowCloneMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (workflowId: CloneWorkflowRequest) => workflowApi.cloneWorkflowsV1(workspaceId, workflowId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all });
      },
    },
  );
};

export const useWorkflowScheduleDeleteMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.deleteScheduledWorkflowV1(workspaceId, workflowId), {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: workflowsQueryKeys.all,
      }),
  });
};

export const useTriggerEmailWorkflowRunQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.emailTrigger(workspaceId, workflowId),
    () => workflowApi.getTriggerEmailV1(workspaceId, workflowId),
    {
      select: data => data.data,
    },
  );
};
