import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AppBuilderStatusKeys,
  ChatterRequestModel,
  CreateAppBuilderRequest,
  CreateAppBuilderResponse,
  DataSourceConversationModel,
  ExecuteAppBuilderRequest,
  GeneratePromptForLLMRequest,
  UpdateAppBuilderRequest,
} from '../../generated/api';
import { appBuilderApi, chatterApi } from '../../lib/api';
import { HTTPError } from '../../lib/api/api';
import {
  ChatResourceTypes,
  onErrorChatWithData,
  onMutateChatWithData,
  onSuccessChatWithData,
} from '../chat-with-data/chat';
import { chatWithDataKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

const APP_BUILDER_PAGE_SIZE = 8;

export const appBuilderKeys = {
  all: ['app-builder'] as const,
  list: (workspaceId: string, appStatus: AppBuilderStatusKeys) =>
    [...appBuilderKeys.all, 'list', workspaceId, appStatus] as const,
  detail: (workspaceId: string, appId: string) =>
    [...appBuilderKeys.all, 'detail', workspaceId, appId] as const,
  modelList: (workspaceId: string) => [...appBuilderKeys.all, 'model-list', workspaceId] as const,
  appStoreExecute: (workspaceId: string, appId: string, question: string, timestamp: number) =>
    [...appBuilderKeys.all, 'app-store-execute', workspaceId, appId, question, timestamp] as const,
  promptSuggestions: (workspaceId: string, appId: string) =>
    [...appBuilderKeys.all, 'prompt-suggestions', workspaceId, appId] as const,
  save: (workspaceId: string, appId: string) =>
    [...appBuilderKeys.all, 'save', workspaceId, appId] as const,
};

export const useCreateAppBuilderMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<CreateAppBuilderResponse>,
    AxiosError<HTTPError>,
    CreateAppBuilderRequest
  >((req: CreateAppBuilderRequest) => appBuilderApi.createAppBuilderV1(workspaceId, req), {
    onSuccess: () => {
      queryClient.invalidateQueries(appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Draft));
      queryClient.invalidateQueries(
        appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Published),
      );
    },
  });
};

export const useGetAppBuilderListQuery = (appStatus: AppBuilderStatusKeys) => {
  const { workspaceId } = useAppMetadata();
  return useInfiniteQuery(
    appBuilderKeys.list(workspaceId, appStatus),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * APP_BUILDER_PAGE_SIZE,
        end = start + APP_BUILDER_PAGE_SIZE;
      return appBuilderApi.listAppBuilderV1(workspaceId, start, end, lastTimestamp, appStatus);
    },
    {
      getNextPageParam: getNextPageParamHandler(APP_BUILDER_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(workspaceId),
      refetchInterval: 30 * 1000,
    },
  );
};

export const useGetAppBuilderDetailsQuery = (appId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    appBuilderKeys.detail(workspaceId, appId),
    () => appBuilderApi.getAppBuilderDetailsV1(workspaceId, appId),
    {
      select: data => data.data.data,
    },
  );
};

export const useUpdateAppBuilderMutation = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation(
    (req: UpdateAppBuilderRequest & { appStatus?: AppBuilderStatusKeys }) =>
      appBuilderApi.updateAppBuilderV1(workspaceId, appId, req),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(appBuilderKeys.detail(workspaceId, appId));
        if (req?.appStatus) {
          queryClient.invalidateQueries(appBuilderKeys.list(workspaceId, req.appStatus));
        }
      },
      mutationKey: appBuilderKeys.save(workspaceId, appId),
    },
  );
};

export const useExecuteAppBuilderMutation = (appId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: ExecuteAppBuilderRequest) =>
    appBuilderApi.executeAppV1(workspaceId, appId, req),
  );
};

export const useExecuteAppQuery = (appId: string, question: string, timestamp: number) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    appBuilderKeys.appStoreExecute(workspaceId, appId, question, timestamp),
    () => appBuilderApi.executeAppV1(workspaceId, appId, { question }),
    {
      select: data => data.data.modelResponse,
    },
  );
};

export const useGetAvailableModelsForAppBuilderQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    appBuilderKeys.modelList(workspaceId),
    () => appBuilderApi.modelsAppBuilderV1(workspaceId),
    {
      select: data => data.data.models,
    },
  );
};

export const usePublishAppFromAppBuilder = (appId: string, appStatus: AppBuilderStatusKeys) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => appBuilderApi.publishAppBuilderV1(workspaceId, appId), {
    onSuccess: () => {
      queryClient.invalidateQueries(appBuilderKeys.detail(workspaceId, appId));

      if (appStatus === AppBuilderStatusKeys.Draft) {
        // Draft and Published are invalidated
        // because the app is deleted from the Draft list and added to the Published list
        queryClient.invalidateQueries(
          appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Published),
        );
        queryClient.invalidateQueries(appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Draft));
      } else if (appStatus === AppBuilderStatusKeys.Archived) {
        // Archived and Published are invalidated
        // because the app is deleted from the Archived list and added to the Published list
        queryClient.invalidateQueries(
          appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Published),
        );
        queryClient.invalidateQueries(
          appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Archived),
        );
      }
    },
  });
};

export const useArchiveAppFromAppBuilder = (appId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => appBuilderApi.archiveAppBuilderV1(workspaceId, appId), {
    onSuccess: () => {
      queryClient.invalidateQueries(appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Draft));
      queryClient.invalidateQueries(
        appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Published),
      ),
        queryClient.invalidateQueries(
          appBuilderKeys.list(workspaceId, AppBuilderStatusKeys.Archived),
        );
    },
  });
};

export const usePromptSuggestionsQuery = (appId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    appBuilderKeys.promptSuggestions(workspaceId, appId),
    () => appBuilderApi.getPromptSuggestionsV1(workspaceId, appId),
    {
      select: data => data?.data?.suggestions,
    },
  );
};

export const useGeneratePromptMutation = (appId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: GeneratePromptForLLMRequest) =>
    appBuilderApi.generatePromptForLLMV1(workspaceId, appId, req),
  );
};

export const useChatWithCustomAppMutation = (appId: string, threadId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  const resourceType = ChatResourceTypes.CUSTOM_APP;

  return useMutation<
    AxiosResponse<DataSourceConversationModel, unknown>,
    AxiosError<HTTPError>,
    ChatterRequestModel
  >(
    (chatWithDataRequestModel: ChatterRequestModel) =>
      chatterApi.chatWithAppBuilderV1(workspaceId, appId, chatWithDataRequestModel),
    {
      mutationKey: chatWithDataKeys.query(workspaceId, appId, threadId, resourceType),
      onMutate: onMutateChatWithData(queryClient, workspaceId, appId, threadId, resourceType),
      onSuccess: onSuccessChatWithData(queryClient, workspaceId, appId, threadId, resourceType),
      onError: onErrorChatWithData(queryClient, workspaceId, appId, threadId, resourceType),
    },
  );
};
