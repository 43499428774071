import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { UpdateWorkflowRequest } from '../../../generated/api';
import { workflowApi } from '../../../lib/api';
import { workflowsQueryKeys } from '../list/list';

export const useGetWorkflowDetailsQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.detail(workspaceId, workflowId),
    () => workflowApi.getWorkflowV1(workspaceId, workflowId),
    {
      select: data => data.data,
      enabled: !!workflowId && !!workspaceId,
    },
  );
};

export const useUpdateWorkflowDetailsMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateWorkflowRequest) => workflowApi.updateWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all });
      },
    },
  );
};
