import { IconInfoCircleFilled } from '@tabler/icons-react';
import { Button, HoverCard, Text } from '../../../../../design-system/v2';
import { OperatorModel } from '../../../../../generated/api';
import { OperatorDescriptionCard } from '../../../list/operators/OperatorDescriptionCard';
import { getOperatorIcon } from '../../../util';

const CARD_WIDTH = 400;

interface ExampleModalProps {
  operator: OperatorModel;
}

export const OperatorExampleModal = ({ operator }: ExampleModalProps) => {
  const { name: label, description, example } = operator;
  const icon = getOperatorIcon(operator.iconUrl);

  return (
    <HoverCard
      withinPortal
      width={CARD_WIDTH}
      openDelay={300}
      closeDelay={300}
      position="right-start"
    >
      <HoverCard.Target>
        <Button
          color="gray.6"
          leftIcon={<IconInfoCircleFilled size={20} />}
          styles={{ leftIcon: { marginRight: 8 } }}
        >
          <Text variant="bodyShort02" color="gray.6">
            Example
          </Text>
        </Button>
      </HoverCard.Target>
      <HoverCard.Dropdown p={0} bg="dark.6" sx={theme => ({ borderRadius: theme.spacing.sm })}>
        <OperatorDescriptionCard
          icon={icon}
          title={label}
          description={description}
          example={example}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
