import { IconWand } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import {
  Button,
  ScrollArea,
  Text,
  TextArea,
  useScrollIntoView,
  Vertical,
} from '../../../../design-system/v2';
import { GetPromptSuggestionsResponse } from '../../../../generated/api';
import GeneratedPromptContainer from './GeneratedPromptContainer';
import SuggestionsList from './SuggestionsList';

export interface GeneratePromptModalProps {
  isLoadingSuggestions: boolean;
  suggestionsList: GetPromptSuggestionsResponse['suggestions'];
  isGeneratingPrompt: boolean;
  generatedPrompt: string;
  onGenerateRequest: (question: string) => void;
  isGeneratePromptError: boolean;
  onGeneratedPromptSelect: (response: string) => void;
}

const GeneratePromptModal = ({
  isGeneratingPrompt: isGeneratingPrompt,
  isGeneratePromptError: isGeneratePromptError,
  generatedPrompt,
  onGenerateRequest,
  onGeneratedPromptSelect,
  suggestionsList,
  isLoadingSuggestions,
}: GeneratePromptModalProps) => {
  const [promptInput, setPromptInput] = useState('');
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>({
    offset: 150,
    duration: 500,
  });

  // When the prompt arrives, scroll the container to make the prompt make readable
  useEffect(() => {
    if (generatedPrompt) {
      scrollIntoView({ alignment: 'start' });
    }
  }, [generatedPrompt]);

  const handleSuggestionsSelect = (description: string) => {
    setPromptInput(description);
  };

  const handleGenerate = () => {
    onGenerateRequest(promptInput);
  };

  const handlePromptInputChange = (value: string) => {
    setPromptInput(value);
  };

  return (
    <ScrollArea.Autosize viewportRef={scrollableRef} mah="75vh" type="hidden">
      <Vertical>
        <Vertical align="center">
          <IconWand size={48} />
          <Text variant="subTitle01">Describe your task</Text>
          <Text maw="75%" ta="center" variant="bodyShort03" color="gray.7">
            Turn a task description into a hight quality prompt.
            <br /> Include any required input data and output format, or try an example
          </Text>
        </Vertical>
        <SuggestionsList
          isLoading={isLoadingSuggestions}
          suggestionsList={suggestionsList}
          onSuggestionSelect={handleSuggestionsSelect}
        />
        <TextArea
          autoFocus
          minRows={3}
          maxRows={5}
          ariaLabel="Generate Prompt"
          value={promptInput}
          onChange={handlePromptInputChange}
        />
        <Button
          style={{ alignSelf: 'center' }}
          variant="primary"
          onClick={handleGenerate}
          disabled={isGeneratingPrompt || promptInput.length === 0}
        >
          Generate Prompt
        </Button>
        <GeneratedPromptContainer
          ref={targetRef}
          onPromptSelect={onGeneratedPromptSelect}
          isLoading={isGeneratingPrompt}
          isError={isGeneratePromptError}
          generatedPrompt={generatedPrompt}
        />
      </Vertical>
    </ScrollArea.Autosize>
  );
};

export default GeneratePromptModal;
