import first from 'lodash/first';
import { Navigate } from 'react-router-dom';
import { FullPageLoader } from '../design-system';
import { useQueryParams } from '../hooks/useQueryParams';
import { useWorkspaceList } from '../queries/userHome';
import { AppRoutes, getRoute } from './constants';

interface RouteRedirectionProps {
  route: string;
}

export const RouteRedirection = ({ route }: RouteRedirectionProps): JSX.Element => {
  const { isLoading, data } = useWorkspaceList();
  const query = useQueryParams();
  const inviteWorkspaceId = query.get('workspaceId');
  const onboardComplete = query.get('onboardComplete') === 'true'; // Check for onboardComplete param

  if (isLoading) {
    return <FullPageLoader text="Loading your details..." />;
  }

  const workspaceId = inviteWorkspaceId ?? first(data)?.workspaceId;

  const targetRoute = onboardComplete
    ? `/${workspaceId}/${route}?onboardComplete=true`
    : `/${workspaceId}/${route}`;

  return workspaceId ? (
    <Navigate to={targetRoute} />
  ) : (
    <Navigate to={getRoute(AppRoutes.BASE_ROUTE)} />
  );
};
