import { SerializedStyles } from '@emotion/react';
import { IconEye, IconPencil, IconTools, IconTrashX } from '@tabler/icons-react';
import { PropsWithChildren, useMemo, useState } from 'react';
import {
  Handle,
  HandleType,
  NodeProps,
  Position,
  ReactFlowState,
  getConnectedEdges,
  useNodeId,
  useStore,
} from 'reactflow';
import { useCreateWorkflow } from '../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../contexts/workflows/WorkflowRunContext';
import {
  ActionIcon,
  Box,
  Center,
  Flex,
  Horizontal,
  Loader,
  Text,
  Vertical,
  useMarkovTheme,
} from '../../../design-system/v2';
import {
  OperatorCategory,
  OperatorIODescription,
  WorkflowRunOperatorStatus,
} from '../../../generated/api';
import { useDebugRunStatusQuery } from '../../../queries/workflows/debug';
import {
  useGetOperatorDetailsQuery,
  useGetWorkflowDagNodesSchemaV2Query,
} from '../../../queries/workflows/operators';
import { Mode } from '../create/utils';
import {
  FormModal,
  ReadOnlyFormModal,
} from '../create/workflow-builder/operator-parameter-form/FormModal';
import { getOperatorIcon } from '../util';
import { MAX_ALLOWED_CONNECTIONS_PER_HANDLE, NodeData, getColorsFromStatus } from './utils';

interface InputOutputHandleProps {
  position: Position;
  handleType: HandleType;
  io: OperatorIODescription[];
}

interface NodeActionIconProps {
  onClickAction: () => void;
  icon: JSX.Element;
}

export const NODE_SIZE = 96;
export const NODE_BORDER = 2;
export const NODE_REVOLVING_BORDER = 10;
const ICON_SIZE = 40;

const selector = (s: ReactFlowState) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const InputOutputHandle = ({ position, handleType, io }: InputOutputHandleProps) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    if (!nodeId) return false;
    const node = nodeInternals.get(nodeId);
    if (!node) return false;
    return getConnectedEdges([node], edges).length < MAX_ALLOWED_CONNECTIONS_PER_HANDLE;
  }, [nodeInternals, edges, nodeId]);

  const handleHeight = 12;
  const spacing = 20;
  const handles = io.map((item, index) => {
    const offset = (index - (io.length - 1) / 2) * (handleHeight + spacing);
    const topPosition = NODE_SIZE / 2 + offset;
    return (
      <Handle
        key={item.id}
        id={item.id}
        type={handleType}
        position={position}
        isConnectable={isHandleConnectable}
        style={{
          [position === Position.Left ? 'left' : 'right']: '-6px',
          backgroundColor: '#ADB5BD',
          height: `${handleHeight}px`,
          width: `${handleHeight}px`,
          top: `${topPosition}px`,
        }}
      />
    );
  });

  return <>{handles}</>;
};

const InputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Left} handleType="target" io={io} />
);

const OutputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Right} handleType="source" io={io} />
);

const NodeActionIcon = ({ onClickAction, icon }: NodeActionIconProps) => (
  <ActionIcon onClick={onClickAction}>
    <Box
      p="xs"
      bg="white.0"
      sx={{
        borderRadius: '50%',
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
      }}
    >
      {icon}
    </Box>
  </ActionIcon>
);

const NodeContainer = ({
  bgColor,
  borderColor,
  revolvingBorder,
  nodeErrors,
  shape = 'circle',
  children,
}: PropsWithChildren<{
  bgColor: string;
  borderColor: string;
  revolvingBorder?: SerializedStyles;
  nodeErrors?: string[];
  shape?: 'circle' | 'rectangle';
}>) => {
  const theme = useMarkovTheme();
  const borderRadius = shape === 'circle' ? '50%' : '12px';
  const borderStyle = revolvingBorder
    ? `none`
    : nodeErrors?.length
    ? `${NODE_BORDER}px solid ${theme.colors.orange[2]}`
    : `${NODE_BORDER}px solid ${borderColor}`;

  return (
    <Center sx={{ ...revolvingBorder }}>
      <Center
        p="xl"
        pos="relative"
        bg={bgColor}
        w={NODE_SIZE}
        h={NODE_SIZE}
        className="node-component"
        sx={{
          border: borderStyle,
          borderRadius,
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        }}
      >
        {children}
        {(nodeErrors ?? []).length > 0 && (
          <Center
            pos="absolute"
            p="xs"
            top={shape === 'circle' ? NODE_SIZE - 28 : NODE_SIZE - 18}
            left={shape === 'circle' ? NODE_SIZE - 28 : NODE_SIZE - 18}
            bg="orange.4"
            sx={{ border: `${NODE_BORDER}px solid ${theme.colors.orange[2]}`, borderRadius: '50%' }}
          >
            <IconTools size={16} color={theme.colors.white[0]} />
          </Center>
        )}
      </Center>
    </Center>
  );
};

const NodeComponent = ({
  id,
  data,
  shape,
  category,
}: {
  id: string;
  data: NodeData;
  shape: 'circle' | 'rectangle';
  category: OperatorCategory;
}) => {
  const theme = useMarkovTheme();
  const { workflowId, onNodeDelete, mode } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();
  const [isOpen, setIsOpen] = useState(false);

  const { data: operator, isLoading } = useGetOperatorDetailsQuery(data.id);
  const {
    data: dagNodeSchema,
    isLoading: isDagNodeSchemaLoading,
    isFetching,
  } = useGetWorkflowDagNodesSchemaV2Query(workflowId, id);
  const { data: debugRunStatus } = useDebugRunStatusQuery(workflowId, runId);
  const ioSchema = dagNodeSchema?.nodeSchemas;
  const nodeErrors = ioSchema?.[id]?.errors;
  const operatorStatus = debugRunStatus?.operatorsStatus?.find(status => status.nodeId === id)
    ?.statusDetails.status;

  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    operatorStatus ?? WorkflowRunOperatorStatus.NotStarted,
    category,
  );

  const handleEditClick = () => {
    if (!isLoading) setIsOpen(true);
  };

  const handleDelete = () => onNodeDelete(id);
  const handleFormClose = () => setIsOpen(false);

  return (
    <Vertical
      align="center"
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
          ['.node-component']: {
            border: revolvingBorder
              ? `none`
              : mode === Mode.Debug
              ? `${NODE_BORDER}px solid ${borderColor}`
              : nodeErrors?.length
              ? `${NODE_BORDER}px solid ${theme.colors.orange[2]}`
              : `${NODE_BORDER}px solid #82CBF9`,
          },
        },
      }}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132} title={operator?.name}>
        {operator?.name}
      </Text>
      <Box onClick={handleEditClick} data-testid={`operator-${operator?.operatorId}`}>
        <NodeContainer
          bgColor={bgColor}
          borderColor={borderColor}
          shape={shape}
          revolvingBorder={revolvingBorder}
          nodeErrors={mode === Mode.Debug ? [] : nodeErrors}
        >
          {isDagNodeSchemaLoading || isFetching || isLoading ? (
            <Loader size={40} />
          ) : (
            getOperatorIcon(operator?.iconUrl || '', ICON_SIZE)
          )}
          <InputHandle io={operator?.inputs || []} />
          <OutputHandle io={operator?.outputs || []} />
        </NodeContainer>
      </Box>

      {!isLoading && (
        <Horizontal spacing="sm" className="action-icon" sx={{ visibility: 'hidden' }}>
          <NodeActionIcon
            onClickAction={handleEditClick}
            icon={<IconPencil color={theme.colors.gray[7]} width={20} height={20} />}
          />
          <NodeActionIcon
            onClickAction={handleDelete}
            icon={<IconTrashX color={theme.colors.gray[7]} width={20} height={20} />}
          />
        </Horizontal>
      )}
      <FormModal
        id={id}
        isOpen={isOpen}
        handleFormClose={handleFormClose}
        operatorModel={operator}
        configuration={data.configuration}
      />
    </Vertical>
  );
};

const NodeViewComponent = ({
  id,
  data,
  shape,
  category,
  renderNodeActions,
}: {
  id: string;
  data: NodeData;
  shape: 'circle' | 'rectangle';
  category: OperatorCategory;
  renderNodeActions?: boolean;
}) => {
  const theme = useMarkovTheme();
  const { workflowId, runId, isDebugMode } = useWorkflowRunContext();

  const { data: operator, isLoading } = useGetOperatorDetailsQuery(data.id);

  const [isOpen, setIsOpen] = useState(false);

  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);
  const { bgColor, borderColor, revolvingBorder } = getColorsFromStatus(
    runStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    category,
  );

  const handleViewClick = () => {
    if (!isLoading) setIsOpen(true);
  };

  const handleFormClose = () => {
    setIsOpen(false);
  };

  return (
    <Vertical
      align="center"
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
          ['.node-component']: {
            border:
              runId.length === 0
                ? `${NODE_BORDER}px solid #82CBF9`
                : revolvingBorder
                ? `none`
                : `${NODE_BORDER}px solid ${borderColor}`,
          },
        },
      }}
    >
      <Text variant="subTitle04" color="gray.6" align="center" maw={132} title={operator?.name}>
        {operator?.name}
      </Text>
      <Box onClick={handleViewClick} data-testid={`operator-${operator?.operatorId}`}>
        <NodeContainer
          bgColor={bgColor}
          borderColor={borderColor}
          shape={shape}
          revolvingBorder={revolvingBorder}
        >
          {!isLoading ? getOperatorIcon(operator?.iconUrl || '', ICON_SIZE) : <Loader size={40} />}
          <InputHandle io={operator?.inputs || []} />
          <OutputHandle io={operator?.outputs || []} />
        </NodeContainer>
      </Box>

      {!renderNodeActions && (
        <Flex justify="center" className="action-icon" sx={{ visibility: 'hidden' }}>
          <NodeActionIcon
            onClickAction={handleViewClick}
            icon={<IconEye color={theme.colors.gray[7]} width={24} height={24} />}
          />
        </Flex>
      )}
      <ReadOnlyFormModal
        id={id}
        workflowId={workflowId}
        isOpen={isOpen}
        handleClose={handleFormClose}
        operatorModel={operator}
        configuration={data.configuration}
        isDebugMode={isDebugMode}
      />
    </Vertical>
  );
};

export const SourceOperator = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="circle" category={OperatorCategory.Source} />
);

export const ProcessView = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="rectangle" category={OperatorCategory.Process} />
);

export const DestinationOperator = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="circle" category={OperatorCategory.Sink} />
);

export const getNodeViewerTypes = (renderNodeActions: boolean) => ({
  [OperatorCategory.Source]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="circle"
      category={OperatorCategory.Source}
      renderNodeActions={renderNodeActions}
    />
  ),
  [OperatorCategory.Sink]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="circle"
      category={OperatorCategory.Sink}
      renderNodeActions={renderNodeActions}
    />
  ),
  [OperatorCategory.Process]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="rectangle"
      category={OperatorCategory.Process}
      renderNodeActions={renderNodeActions}
    />
  ),
});

export const nodeTypes = {
  [OperatorCategory.Source]: SourceOperator,
  [OperatorCategory.Sink]: DestinationOperator,
  [OperatorCategory.Process]: ProcessView,
};
