import { IconDotsVertical, IconInfoCircle } from '@tabler/icons-react';
import {
  ActionIcon,
  Alert,
  Box,
  Card,
  closeAllModals,
  Horizontal,
  Menu,
  notifications,
  openConfirmModal,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { GenAIProjectMetadataResponse } from '../../../../../generated/api';
import { toPlural } from '../../../../../lib/ui';
import { useGetWorkspaceMembersQuery } from '../../../../../queries/account/workspace';
import { useDeleteAppProjectMutation } from '../../../../../queries/document-ai/document';
import { SharedWithUsers } from '../../../../common/user-avatar-group/SharedWithUsers';
import { useAppProjectStyle } from './AppProjects.style';
import { useUpdateAppProjectModal } from './UpdateProjectModal';

export interface AppProjectCardProps {
  project: GenAIProjectMetadataResponse;
  onProjectClick: (projectId: string) => () => void;
}

export const AppProjectCard = ({
  project,
  onProjectClick: handleNavigateProjectDetails,
}: AppProjectCardProps): JSX.Element => {
  const { isLoading, data } = useGetWorkspaceMembersQuery();
  const { classes } = useAppProjectStyle();

  const { mutateAsync: deleteProject } = useDeleteAppProjectMutation(
    project.appId,
    project.projectId,
  );

  const { openUpdateAppProjectModal } = useUpdateAppProjectModal(
    project.appId,
    project.projectId,
    project.projectName,
    project.projectDescription,
  );

  const handleConfirmDeleteProject = () => {
    deleteProject()
      .then(() => {
        notifications.success('Project deleted successfully');
      })
      .catch(() => {
        notifications.error('There was an issue deleting the project');
      });
  };

  const handleDeleteProject = (project: GenAIProjectMetadataResponse) => {
    const containsDocuments =
      project.countOfDocuments !== undefined && project.countOfDocuments > 0;
    const isProjectShared = project.invitedUsers !== undefined && project.invitedUsers.length > 1;
    openConfirmModal({
      title: <Text variant="heading03">Delete project?</Text>,
      children: (
        <Vertical spacing="md">
          {containsDocuments && (
            <Alert color="yellow" icon={<IconInfoCircle size={16} />}>
              <Text>
                Your project contains <strong>{`${project.countOfDocuments} document(s)`}</strong>.
                All the documents present in this project will be deleted.
              </Text>
              {isProjectShared && (
                <Text>
                  Also note that, the project is shared with{' '}
                  <strong>{`${project.invitedUsers?.length} member(s)`}</strong>.
                </Text>
              )}
            </Alert>
          )}
          <Text variant="bodyLong03" mb="xl">
            Are you sure you want to delete <strong>{project?.projectName}</strong>? This action
            cannot be undone.
          </Text>
        </Vertical>
      ),
      onConfirm: handleConfirmDeleteProject,
      onCancel: () => {
        closeAllModals();
      },
      labels: { confirm: 'Delete project', cancel: `No, don't delete` },
      confirmProps: { color: 'red' },
    });
  };

  return (
    <Card
      key={project.projectId}
      data-testid={`copy-edit-projectName-${project.projectName}`}
      p={16}
      w={260}
      mih={135}
      className={classes.card}
      onClick={handleNavigateProjectDetails(project.projectId)}
    >
      <Vertical spacing="apart">
        <Vertical spacing={0} mb={28}>
          <Horizontal noWrap>
            <Text variant="subTitle02" truncate title={project.projectName}>
              {project.projectName}
            </Text>
            <Box pos="absolute" top={16} right={12} sx={{ zIndex: 20 }}>
              <Menu withinPortal position="bottom-start" shadow="xl">
                <Menu.Target>
                  <ActionIcon
                    size="sm"
                    onClick={e => {
                      e.stopPropagation(); // Prevent click from bubbling up to the Card
                    }}
                  >
                    <IconDotsVertical />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown miw={200} sx={theme => ({ borderColor: theme.colors.gray[3] })}>
                  <Menu.Item
                    onClick={e => {
                      e.stopPropagation();
                      openUpdateAppProjectModal();
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteProject(project);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Box>
          </Horizontal>

          <Text variant="subTitle05" color="gray.6">
            {project.countOfDocuments ? toPlural(project.countOfDocuments, 'file') : 'No files yet'}
          </Text>
        </Vertical>
        <SharedWithUsers
          isLoading={isLoading}
          allUsers={data?.workspaceMembers ?? []}
          userIdsWithAccess={project.invitedUsers?.map(user => user.userId) ?? []}
          currentActiveUserIds={project.invitedUsers?.map(user => user.userId) ?? []}
          size={28}
        />
      </Vertical>
    </Card>
  );
};
