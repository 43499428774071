import { Suspense } from 'react';
import { Navigate, Route, RouteProps, useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorBoundary, ErrorFallback } from '../ErrorBoundary';
import { Loading } from '../components/common/Loading';
import { Home } from '../components/home/Home';
import { Alert, Center, useTimeout } from '../design-system/v2';
import { TracedRoutes } from '../initializers/sentry';
import { AccessDeniedPage } from '../pages/AccessDenied';
import { LogoutPage } from '../pages/Logout';
import { SignupPage } from '../pages/Signup';
import { PublicSnippetPage } from '../pages/Snippets/PublicSnippet';
import { UnavailablePage } from '../pages/Unavailable';
import { AppContainer } from '../pages/home/AppContainer';
import { useIDPExchangeForGoogleOAuth } from '../queries/connectors/google-drive';
import { AuthRoute, renderAuthRoute } from './AuthRouter';
import { RouteRedirection } from './RouteRedirection';
import { WorkspaceRouter } from './WorkspaceRouter';
import { AppRoutes, getRoute } from './constants';
import { useAbsoluteRoutes } from './hooks';
import { redirectionRouteDetails } from './redirectionConstants';
import { lazyWithRetry } from './util';

declare global {
  interface Window {
    Cypress: any;
  }
}

const Onboarding = lazyWithRetry(() => import('../pages/Onboarding'));
const UserProfilePage = lazyWithRetry(() => import('../pages/UserProfile'));
const AcceptInvitePage = lazyWithRetry(() => import('../pages/AcceptInvite'));

interface RedirectToExistingConnectorProps {
  workspaceId: string;
}

export const RedirectToExistingConnector = ({ workspaceId }: RedirectToExistingConnectorProps) => {
  const navigate = useNavigate();
  const datasourceRoute = `/${workspaceId}/${getRoute(AppRoutes.DATA_SOURCES, {
    tab: 'connectors',
  })}`;

  useTimeout(() => navigate(datasourceRoute), 1000, {
    autoInvoke: true,
  });

  return (
    <Center w="100%" h="100%">
      <Alert color="blue">
        Account already connected. Redirecting to connectors page where you can add source from this
        account
      </Alert>
    </Center>
  );
};

export const GoogleAuthRedirect = () => {
  const [searchParams] = useSearchParams();

  const { getDataSourcesRoute } = useAbsoluteRoutes();

  const workspaceId = searchParams.get('state') ?? '';
  const code = searchParams.get('code') ?? '';

  const { data, isError, isLoading } = useIDPExchangeForGoogleOAuth(workspaceId, code);

  if (isLoading) {
    return (
      <Center w="100%" h="100%">
        <Loading />
        Authenticating google account
      </Center>
    );
  }

  if (isError) {
    return (
      <Center w="100%" h="100%">
        <Alert color="red">There has been an error validating account credentials.</Alert>
      </Center>
    );
  }

  if (data?.message?.code === 'EXISTING' && data.response?.connectorId) {
    return <RedirectToExistingConnector workspaceId={workspaceId} />;
  }

  return <Navigate to={'/' + getDataSourcesRoute()} />;
};

const appRoutes: RouteProps[] = [
  {
    path: getRoute(AppRoutes.ONBOARDING),
    element: <Onboarding />,
  },
  {
    path: getRoute(AppRoutes.INVITE),
    element: <AcceptInvitePage />,
  },
  {
    path: getRoute(AppRoutes.LOGOUT),
    element: <LogoutPage />,
  },
  {
    path: getRoute(AppRoutes.GOOGLE_AUTH_ROUTE),
    element: <GoogleAuthRedirect />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: getRoute(AppRoutes.ACCESS_DENIED),
    element: <AccessDeniedPage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const AppRouter = (): JSX.Element => (
  <TracedRoutes>
    {/* Adding Signup page before so it gets intercepted before other URLs */}
    <Route path={getRoute(AppRoutes.SIGNUP)} element={<SignupPage />} />
    <Route path={getRoute(AppRoutes.PUBLIC_SNIPPET)} element={<PublicSnippetPage />} />
    <Route path="/" element={<AppContainer />}>
      <Route index element={<AuthRoute element={<Home />} />} />
      <Route
        path={getRoute(AppRoutes.USER_PROFILE)}
        element={
          <Suspense fallback={<Loading />}>
            <UserProfilePage />
          </Suspense>
        }
      />
      <Route path=":workspaceId/*" element={<WorkspaceRouter />} />
    </Route>
    {Object.entries(redirectionRouteDetails).map(([path, route]) => (
      <Route
        key={path}
        path={path}
        element={
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense fallback={<Loading />}>
              <AuthRoute path={path} element={<RouteRedirection route={route} />} />
            </Suspense>
          </ErrorBoundary>
        }
      />
    ))}
    {appRoutes.map(renderAuthRoute)}
  </TracedRoutes>
);
