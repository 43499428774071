import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ErrorBar } from '../../components/common/ErrorBar';
import { InfoMessage } from '../../components/common/InfoMessage';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { Box } from '../../design-system';
import { Center, Loader, Text, Vertical } from '../../design-system/v2';
import { MarkovArtifactMigrationStatus } from '../../generated/api';
import { useQueryParams } from '../../hooks/useQueryParams';
import { cleanErrorsInSearchParams } from '../../lib/router';
import { useWorkspaceSetupQuery } from '../../queries/account/workspace';
import { useWorkspaceList } from '../../queries/userHome';
import { AppRoutes, getRoute } from '../../router/constants';
import { WorkspaceShell } from './WorkspaceShell';

export const WorkspaceContainerPage = (): JSX.Element => {
  const { setWorkspaceId } = useAppMetadata();
  const { workspaceId } = useParams();
  const query = useQueryParams();
  const redirect = query.get('redirect');

  const { isLoading, data, error } = useWorkspaceList();
  const { isInitialLoading: isCheckingForSetup, data: isWorkspaceSetup } = useWorkspaceSetupQuery();

  const isValidWorkspace = Boolean(
    workspaceId && data?.some(workspace => workspace.workspaceId === workspaceId),
  );

  useEffect(() => {
    if (isValidWorkspace) {
      setWorkspaceId(workspaceId ?? '');
    }
  }, [workspaceId, setWorkspaceId, isValidWorkspace]);

  if (!workspaceId) {
    return (
      <Navigate
        to={{
          pathname: getRoute(AppRoutes.BASE_ROUTE),
          search: cleanErrorsInSearchParams(window.location.search),
        }}
      />
    );
  }

  if (isLoading || isCheckingForSetup) {
    return (
      <Center h="100%" w="100%">
        <Vertical align="center" spacing="xl">
          <Loader variant="bars" />
          <Text variant="subTitle01" color="gray.9">
            Fetching your workspace details
          </Text>
        </Vertical>
      </Center>
    );
  }

  if (error) {
    return (
      <Box mt={48} mx={24} width="100%">
        <ErrorBar errorMessage="Unable to load your workspace details. Try pressing the Home button or refresh the page." />
      </Box>
    );
  }

  if (!data || !data.length) {
    return (
      <Box mt={48} mx={24} width="100%">
        <InfoMessage text="Looks like you aren't part of any workspace. Please ask existing users to invite to their workspace or contact Support." />
      </Box>
    );
  }

  if (!isValidWorkspace) {
    return <Navigate to={getRoute(AppRoutes.ACCESS_DENIED)} />;
  }

  if (isWorkspaceSetup === MarkovArtifactMigrationStatus.Loading) {
    return (
      <Center h="100%" w="100%">
        <Vertical align="center" spacing="xs">
          <Loader variant="bars" mb="lg" />
          <Text variant="subTitle01" color="gray.9">
            Setting up your workspace
          </Text>
          <Text variant="bodyShort01" color="gray.9">
            Let us customize your experience
          </Text>
        </Vertical>
      </Center>
    );
  }

  return <WorkspaceShell />;
};
