import AppDocViewer, { IDocument, PDFRenderer } from '@cyntler/react-doc-viewer';
import first from 'lodash/first';
import { useMemo } from 'react';
import { Center, Loader } from '../../../design-system/v2';
import { useGetDataSourcePDFUrlQuery } from '../../../queries/data-sources';

const pluginRenderers = [PDFRenderer];
const docTheme = { disableThemeScrollbar: true };
const docConfig = {
  header: {
    disableFileName: true,
    disableHeader: true,
  },
};

interface PDFPreviewProps {
  resourceId: string;
}

export const PDFPreview = ({ resourceId }: PDFPreviewProps) => {
  const { isLoading, isError, data: url } = useGetDataSourcePDFUrlQuery(resourceId);
  const docs: IDocument[] = useMemo((): IDocument[] => {
    if (!url) {
      return [];
    }
    const fileName = first<string>(url.split('/'));

    return [
      {
        uri: url,
        fileType: 'pdf',
        fileName: fileName ?? 'new',
      },
    ];
  }, [url]);

  if (isLoading || !url) {
    return (
      <Center h="100%" w="100%">
        <Loader text="Loading PDF preview..." size="md" />
      </Center>
    );
  }

  return (
    <AppDocViewer
      documents={docs}
      pluginRenderers={pluginRenderers}
      theme={docTheme}
      config={docConfig}
    />
  );
};
