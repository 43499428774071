import { noop } from 'lodash';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useDataUpload } from '../../../contexts/data-upload/DataUpload';
import {
  Box,
  Button,
  FileWithPath,
  Flex,
  MIME_TYPES,
  Text,
  Vertical,
  notifications,
  useInputState,
} from '../../../design-system/v2';
import { connectorsApi } from '../../../lib/api';
import { useGetPresignedUrlMutation } from '../../../queries/data-sources';
import { CompleteParams } from '../../../queries/data-upload';
import { FileUploadInput } from './FileUploadInput';
import { getFilePreviewComponentForFile } from './util';

export type Step = 'upload' | 'preview';

interface FileUploaderProps {
  onUploadQueued: () => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  onStepChange?: (step: Step) => void;
}

export const FileUploader = ({
  onUploadQueued,
  onUploadComplete,
  onStepChange = noop,
}: FileUploaderProps) => {
  const { workspaceId } = useAppMetadata();
  const [step, setStep] = useState<Step>('upload');
  const [file, setFile] = useState<FileWithPath>();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useInputState('');
  const { addUpload } = useDataUpload();
  const { mutateAsync: getPresignedUrl } = useGetPresignedUrlMutation();

  const handlePreviewClick = () => {
    onStepChange('preview');
    setStep('preview');
  };

  const handleBackClick = () => {
    onStepChange('upload');
    setStep('upload');
  };

  const handleUploadSuccess = () => {
    notifications.success('File upload successful', {
      autoClose: true,
    });
  };

  const handleUploadError = (err: string) => {
    notifications.error(`Uploading ${file?.name} failed`, {
      autoClose: true,
    });
  };

  const handleUpload = async () => {
    setIsUploading(true);

    if (file) {
      let newFile = file;

      if (fileName) {
        newFile = new File([file], fileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      }

      let fileParams;

      try {
        fileParams = await getPresignedUrl({ file: newFile });
      } catch (e) {
        notifications.error('Error uploading a file. Pls contact support');
        setIsUploading(false);
        return;
      }

      addUpload(
        uuid(),
        [
          {
            file: newFile,
            uploadParams: fileParams.data.response,
          },
        ],
        {
          useUploadWindow: true,
          onSuccess: handleUploadSuccess,
          onError: handleUploadError,
          onFileUploadComplete: async (completeParams: CompleteParams) => {
            try {
              await connectorsApi.completeUploadResourceV1(workspaceId, completeParams);
              onUploadComplete('success', completeParams);
            } catch (e) {
              onUploadComplete('error', completeParams);
            }
          },
        },
      );

      onUploadQueued();
    }
  };

  const handleFileDelete = () => {
    setFile(undefined);
  };

  if (step === 'preview' && file) {
    return (
      <Box w="100%" h="70vh">
        <Box h="calc(100% - 48px)">
          {getFilePreviewComponentForFile(file, handleBackClick, { setFileName })}
        </Box>
        <Flex mt="md" justify="end">
          <Button variant="primary" loading={isUploading} onClick={handleUpload}>
            Upload
          </Button>
        </Flex>
      </Box>
    );
  }

  const handleFileDrop = (file: FileWithPath) => {
    setFile(file);
  };

  return (
    <Vertical spacing="xs">
      <FileUploadInput
        file={file}
        onFileDrop={handleFileDrop}
        onFileDelete={handleFileDelete}
        dropzoneAccept={{
          [MIME_TYPES.csv]: ['.csv', '.tsv'],
          'text/plain': ['.txt'],
          [MIME_TYPES.pdf]: ['.pdf'],
          [MIME_TYPES.jpeg]: ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi'],
        }}
      />
      <Text variant="bodyShort03" color="gray.7">
        Supported file formats: .csv/.tsv/.txt/.pdf/.jpeg/jpg. File should be UTF-8 encoded. Maximum
        file size: 5GB
      </Text>
      <Flex mt="md" justify="end">
        <Button variant="primary" disabled={!file} onClick={handlePreviewClick}>
          See preview and upload
        </Button>
      </Flex>
    </Vertical>
  );
};
