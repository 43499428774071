import { Connection, MarkerType } from 'reactflow';
import { useMarkovTheme } from '../../../design-system/v2';
import { colors } from '../../../design-system/v2/theme/colors/colors';
import { Edge, WorkflowRunOperatorStatus } from '../../../generated/api';
import { WorkflowEdge } from '../nodes/utils';

export enum EdgeTypes {
  CUSTOM_EDGE = 'custom-edge',
}

export const transformEdgeToWorkflowEdge = (edge: WorkflowEdge) => {
  const newEdge: WorkflowEdge = {
    ...edge,
    type: EdgeTypes.CUSTOM_EDGE,
    markerEnd: {
      color: (colors as any).gray[6],
      height: 20,
      type: MarkerType.ArrowClosed,
      width: 20,
    },
    style: {
      stroke: (colors as any).gray[6],
      strokeWidth: 2,
    },
    data: { isHovered: false },
  };

  return newEdge;
};

export const transformWorkflowEdgeToEdge = (edge: WorkflowEdge): Edge => {
  const newEdge: Edge = {
    id: edge.id,
    source: edge.source,
    target: edge.target,
    sourceHandle: edge.sourceHandle ? edge.sourceHandle : '',
    targetHandle: edge.targetHandle ? edge.targetHandle : '',
  };

  return newEdge;
};

interface UseEdgeColorOptions {
  /**
   * The current status of the operator
   */
  operatorStatus: WorkflowRunOperatorStatus;
  /**
   * A boolean indicating whether the edge is currently hovered.
   */
  isHovered: boolean;
}

/**
 * Custom hook to determine the edge color based on the operator's status and hover state.
 *
 * @param options - The options for determining the edge color.
 *
 * @returns The color corresponding to the operator's status and hover state
 */
export const useEdgeColor = ({ operatorStatus, isHovered }: UseEdgeColorOptions) => {
  const theme = useMarkovTheme();
  switch (operatorStatus) {
    case WorkflowRunOperatorStatus.Success:
      return theme.colors.green[6];
    case WorkflowRunOperatorStatus.Running:
      return theme.colors.yellow[6];
    case WorkflowRunOperatorStatus.NotStarted:
    case WorkflowRunOperatorStatus.Failed:
    default:
      return isHovered ? theme.colors.cyan[6] : theme.colors.gray[5];
  }
};

/**
 * Checks if a given connection is invalid.
 * A connection is considered to be invalid when the target node's input handle already has an edge.
 * (An input handle can have only one edge connected to it)
 *
 * @param connection - The connection object representing the source and target of an edge.
 * @param edges - An array of existing edges to check against.
 *
 * @returns `true` if the connection is invalid, otherwise `false`.
 */
export const isInvalidConnection = (connection: Connection, edges: Array<WorkflowEdge>) =>
  edges.some(
    edge => edge.target === connection.target && edge.targetHandle === connection.targetHandle,
  );
