import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AppIdType,
  BodyRegisterAppDocumentV2,
  DocumentParsingStatusResponse,
  DocumentStatusType,
  DocumentUploadRequest,
  GetAppDocumentsListResponse,
  GetDocumentMetadataResponse,
  GetDocumentSummaryResponse,
} from '../../generated/api';
import { HTTPError } from '../../lib/api/api';
import { appStoreApi } from '../../lib/api/reno';
import { appStoreKeys } from '../queryConstants';

export const useGetAppDocumentListQuery = (appId: AppIdType) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetAppDocumentsListResponse>,
    AxiosError<HTTPError>,
    GetAppDocumentsListResponse
  >(
    appStoreKeys.list(workspaceId, appId),
    () => appStoreApi.getAppDocumentsListV1(workspaceId, appId),
    {
      enabled: Boolean(workspaceId),
      select: data => data.data,
    },
  );
};

export const useDocumentUploadUrlMutation = (appId: AppIdType) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: DocumentUploadRequest) =>
    appStoreApi.createDocumentAssetsUploadUrlV1(workspaceId, appId, req),
  );
};

export interface DocumentRegistrationMutationProps {
  documentId: string;
  requestBody?: BodyRegisterAppDocumentV2;
}

export const useAppDocumentRegisterMutation = (appId: AppIdType) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: DocumentRegistrationMutationProps) =>
      appStoreApi.registerAppDocumentV2(workspaceId, req.documentId, appId, req.requestBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(appStoreKeys.list(workspaceId, appId));
      },
    },
  );
};

export const useGetDocumentStatusQuery = (appId: AppIdType, documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<
    AxiosResponse<DocumentParsingStatusResponse>,
    AxiosError<HTTPError>,
    DocumentParsingStatusResponse
  >(
    appStoreKeys.status(workspaceId, documentId),
    () => appStoreApi.getDocumentParsingStatusV1(workspaceId, documentId, appId),
    {
      enabled: Boolean(documentId && workspaceId),
      select: data => data.data,
      refetchInterval: data => (data?.status !== DocumentStatusType.Finished ? 5 * 1000 : false),
      onSuccess: data => {
        if (data?.status === DocumentStatusType.Finished) {
          queryClient.invalidateQueries(
            appStoreKeys.details(workspaceId, AppIdType.Summarization, documentId),
          );
          queryClient.invalidateQueries(appStoreKeys.list(workspaceId, AppIdType.Summarization));
        }
      },
    },
  );
};

export const useGetDocumentSummarization = (documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse, AxiosError<HTTPError>, GetDocumentSummaryResponse>(
    appStoreKeys.details(workspaceId, AppIdType.Summarization, documentId),
    () => appStoreApi.getDocumentSummaryV1(workspaceId, documentId),
    {
      enabled: Boolean(documentId && workspaceId),
      select: data => data.data,
    },
  );
};

export const useGetDocumentMetadata = (documentId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse, AxiosError<HTTPError>, GetDocumentMetadataResponse>(
    appStoreKeys.document(workspaceId, documentId),
    () => appStoreApi.getDocumentMetadataV1(workspaceId, documentId),
    {
      select: data => data.data,
      enabled: Boolean(workspaceId),
    },
  );
};

export const useSaveContentMutation = (documentId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (content: string) =>
      appStoreApi.saveDocumentSummaryV1(workspaceId, documentId, {
        formatted_document_summary: content,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          appStoreKeys.details(workspaceId, AppIdType.Summarization, documentId),
        );
      },
    },
  );
};
