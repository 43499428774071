import { IconFileText, IconFileUpload, IconTrashX } from '@tabler/icons-react';
import first from 'lodash/first';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Dropzone,
  FileButton,
  FileWithPath,
  Horizontal,
  MIME_TYPES,
  Text,
  Vertical,
  notifications,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { permittedFileTypes } from './utils';

// const FILE_SIZE_ALLOWED = 3;

interface DocumentUploadInputProps {
  inputFile: File | undefined;
  setInputFile: (file: File | undefined) => void;
}

export const DocumentUploadInput = ({
  inputFile,
  setInputFile,
}: DocumentUploadInputProps): JSX.Element => {
  const theme = useMarkovTheme();

  const handleDropFile = (file: FileWithPath[]) => {
    const firstFile = first(file);

    if (!firstFile) {
      return;
    }

    //TO-DO: Uncomment this when we have a file size limit set.
    // const fileSize = Number(
    //   ((firstFile.size ?? FILE_SIZE_ALLOWED) / MEMORY_UNIT_LIMITS.MB).toFixed(4),
    // );

    if (!permittedFileTypes.includes(firstFile.type)) {
      notifications.error('The file format is not permitted');
      return;
    }

    // TO-DO: Uncomment this error message, once we define a limit.
    // if (fileSize > FILE_SIZE_ALLOWED) {
    //   notifications.error(`The file size exceeds ${FILE_SIZE_ALLOWED}MB.`);
    //   return;
    // }

    setInputFile(firstFile);
  };

  const handleUploadFileSelect = (file: File | null) => {
    if (file) {
      setInputFile(file);
    }
  };

  const handleFileDelete = () => {
    setInputFile(undefined);
  };

  return (
    <Box pos="relative" h={240} aria-labelledby="file-upload">
      <Dropzone
        id="file-upload"
        accept={{
          [MIME_TYPES.docx]: ['.docx'],
          [MIME_TYPES.pdf]: ['.pdf'],
        }}
        onDrop={handleDropFile}
        p={44}
        w="100%"
        h="100%"
        bg={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.0'}
        styles={{
          inner: { height: '100%' },
        }}
        sx={{
          border: `2px dashed ${theme.colors.gray[4]}`,

          ...theme.fn.hover({
            backgroundColor: theme.fn.themeColor('white.1'),
            borderColor: theme.fn.themeColor('white.1'),
          }),
        }}
        aria-describedby="file-upload-instructions"
      >
        <Center h="100%">
          {inputFile ? (
            <Horizontal>
              <IconFileText color={theme.colors.gray[6]} />
              <Text variant="textLink">{inputFile.name}</Text>
            </Horizontal>
          ) : (
            <Vertical align="center" spacing="md">
              <FileButton
                onChange={handleUploadFileSelect}
                accept="text/pdf"
                aria-label="File upload button"
              >
                {props => (
                  <Vertical align="center" spacing={0}>
                    <Button
                      sx={{ borderRadius: theme.radius.xl }}
                      leftIcon={<IconFileUpload color={theme.colors.gray[8]} />}
                      variant="secondary"
                      {...props}
                    >
                      <Text variant="small01">Choose a file</Text>
                    </Button>
                  </Vertical>
                )}
              </FileButton>
              <Box ta="center">
                <Text id="file-upload-instructions" variant="bodyShort03" color="gray.6">
                  Supported file formats: docx, pdf (pptx coming soon)
                </Text>
                {/* <Text variant="bodyShort03" color="gray.6">
                  Maximum file size: {FILE_SIZE_ALLOWED}MB
                </Text> */}
              </Box>
            </Vertical>
          )}
        </Center>
      </Dropzone>
      {inputFile && (
        <Box pos="absolute" right={24} top={104}>
          <ActionIcon onClick={handleFileDelete} aria-label="Delete file">
            <IconTrashX />
          </ActionIcon>
        </Box>
      )}
    </Box>
  );
};
