import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Horizontal,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../../design-system/v2';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { Searchbox } from '../../../common/search-box/Searchbox';
import { RecentDraftWorkflowsContainer } from '../../../workflows/home/overview/RecentDraftWorkflows.container';
import { WorkflowTemplateSection } from './WorkflowTemplateSection';

interface WorkflowHomepageProps {
  onboardComplete: string | null;
}

export const GRADIENT_BACKGROUND = 'linear-gradient(90deg, #E9EFFF 0%, #F4EDFB 100%)';

export const WorkflowHomepage = ({ onboardComplete }: WorkflowHomepageProps): JSX.Element => {
  const theme = useMarkovTheme();
  const navigate = useNavigate();
  const { getWorkflowsDraftRoute } = useAbsoluteRoutes();
  const [searchText, setSearchText] = useState('');

  return (
    <Vertical>
      <Box w="100%" mih="100%" bg={GRADIENT_BACKGROUND}>
        <Container py="lg" size="xl">
          <Text variant="heading03" color="gray.9">
            Workflows
          </Text>
          <Text variant="bodyShort02" color="gray.7" my="xs">
            Craft and streamline your workflow: Effortlessly automate your operational logic
          </Text>
          <Searchbox
            onSearch={setSearchText}
            width="100%"
            margin="32px 0 24px"
            variant="big"
            placeholder="What are you looking to automate?"
            sx={{
              input: {
                height: '56px',
                '::placeholder': {
                  lineHeight: '24px',
                  fontWeight: 300,
                  fontSize: '17px',
                  color: theme.colors.gray[6],
                },
              },
            }}
          />
          <WorkflowTemplateSection searchText={searchText} isHomePage />
        </Container>
      </Box>
      <Vertical>
        <Container py="lg" size="xl" w="100%">
          <Horizontal position="apart" w="100%">
            <Text variant="heading04" color="gray.8" pb="sm">
              Recent drafts
            </Text>
            <Button variant="outline" onClick={() => navigate(getWorkflowsDraftRoute())}>
              <Text variant="subTitle03" color="blue.6">
                See all drafts
              </Text>
            </Button>
          </Horizontal>
          <RecentDraftWorkflowsContainer />
        </Container>
      </Vertical>
    </Vertical>
  );
};
