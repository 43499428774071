import type { JsonFormsCore, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { createAjv } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import type Ajv from 'ajv';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Button, Horizontal, Vertical } from '../../../design-system/v2';
import { OperatorCategory, OperatorModel } from '../../../generated/api';
import { Mode } from '../../workflows/create/utils';
import { renderers } from './renderers';

export interface FormSchema {
  schema: JsonSchema;
  uischema?: UISchemaElement;
}

interface FormProps {
  schema: FormSchema;
  data?: any;
  setData?: React.Dispatch<any>;
  isFormDirty?: boolean;
  setIsFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  initialState?: any;
  // JsonForms does not export error type ;(
  onChange: (data: any) => void;

  onErrors: (errors: any[]) => void;

  isSubmitting?: boolean;
  onSubmit: (data: any) => void;

  viewOnly?: boolean;
  workflowId?: string;
  runId?: string;
  nodeId?: string;
  operator?: OperatorModel;
  prevNodeId?: string;
  isFormSaving?: boolean;
  mode?: Mode;
}

export const Form = ({
  schema,
  data,
  setData,
  isFormDirty,
  setIsFormDirty,
  initialState,
  onErrors,
  isSubmitting,
  onSubmit,
  viewOnly,
  operator,
  workflowId,
  runId,
  nodeId,
  prevNodeId,
  isFormSaving,
  mode,
}: FormProps) => {
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(!isEqual(data, initialState));
    }
  }, [data, initialState]);

  useEffect(() => {
    if (showErrors || isEqual(data, initialState)) {
      return;
    }

    setShowErrors(true);
  }, [data]);

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (errors && errors.length > 0) {
      onErrors(errors);
    }

    if (setData) {
      setData(data);
    }
  };

  const handleSubmit = () => {
    onSubmit(data);
  };

  const applicableUiSchema = schema.uischema;
  const operatorCategory = operator?.category as OperatorCategory;
  const operatorId = operator?.operatorId;

  // There is some issue with types here. CreateAjv return is AJV but does not match
  // with JsonForms prop somehow (using same package).
  // TODO:: Debug type issue further
  const handleDefaultsAjv = createAjv({ useDefaults: true }) as unknown as Ajv;

  return (
    <Vertical h="100%" spacing={0} justify="space-between">
      <JsonForms
        schema={schema.schema}
        uischema={applicableUiSchema}
        data={data}
        config={{
          workflowId,
          runId,
          nodeId,
          viewOnly,
          prevNodeId,
          operatorCategory,
          operatorId,
          isFormDirty,
          isFormSaving,
          mode,
        }}
        renderers={renderers}
        onChange={handleFormChange}
        readonly={viewOnly || isSubmitting}
        validationMode={'ValidateAndShow'}
        ajv={handleDefaultsAjv}
      />
      <Horizontal noWrap>
        {!viewOnly ? (
          <Horizontal pt="lg" noWrap>
            <Button
              w="168px"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting || !isFormDirty}
              onClick={handleSubmit}
            >
              Apply changes
            </Button>
          </Horizontal>
        ) : null}
      </Horizontal>
    </Vertical>
  );
};
