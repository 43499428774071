// import { CellClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { IconReload } from '@tabler/icons-react';
import { useMemo, useRef, useState } from 'react';
import { ActionIcon, Horizontal } from '../../../../../../design-system/v2';
import { List, ListVersions } from '../../../../../../design-system/v2/core/data-display/table';
import {
  CellClickedEvent,
  RowClickedEvent,
} from '../../../../../../design-system/v2/core/data-display/table/ag-grid';
import { ResourceModel, StorageType } from '../../../../../../generated/api';
import { useGetConnectorResourcesListQueryV3 } from '../../../../../../queries/data-sources';
import { ErrorState } from '../../../../../common/ErrorState';
import { getDataSourceColumns, PREVIEW_COL_ID } from '../../../../../data-sources/DataSourcesTable';
import CollapsibleSearch from '../../../../list/toolbar/CollapsibleSearch';
import { useStyles } from '../../../../list/toolbar/ToolbarActionIcon.styles';

const PAGE_SIZE = 20;

interface ConnectorSourceTableContainerProps {
  connectorType: StorageType;
  onSelectResource: (resourceId: string) => void;
  isDataSink: boolean;
}

interface ActionsToolbarProps {
  isLoading: boolean;
  searchText: string;
  onSearchTextChange: (value: string) => void;
  onReload: () => void;
}

export const ActionsToolbar = ({
  searchText,
  onSearchTextChange,
  onReload,
  isLoading,
}: ActionsToolbarProps) => {
  const { classes } = useStyles();

  return (
    <Horizontal spacing="lg">
      <CollapsibleSearch
        ariaLabel="Search data sources"
        value={searchText}
        onChange={onSearchTextChange}
      />
      <ActionIcon className={classes.icon} disabled={isLoading} onClick={onReload}>
        <IconReload width={16} height={16} />
      </ActionIcon>
    </Horizontal>
  );
};

export const ConnectorSourceTableContainer = ({
  connectorType,
  onSelectResource,
  isDataSink,
}: ConnectorSourceTableContainerProps): JSX.Element => {
  const gridRef = useRef<AgGridReact<ResourceModel>>(null);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const dataSourcesQuery = useGetConnectorResourcesListQueryV3(
    [connectorType],
    isDataSink,
    page,
    PAGE_SIZE,
    searchText,
  );

  const dataSourceColumns = useMemo(() => getDataSourceColumns(isDataSink), [isDataSink]);

  if (dataSourcesQuery.error) {
    return (
      <ErrorState
        title="Error fetching available data sources"
        subtitle="Try refreshing the page or contact support if the problem persists"
      />
    );
  }

  const handleCellClick = (event: CellClickedEvent<ResourceModel>) => {
    if (event.column.getColId() === PREVIEW_COL_ID) {
      event.event?.preventDefault();
    }
  };

  const handleRowClick = (event: RowClickedEvent<ResourceModel>) => {
    if (event.event?.defaultPrevented) {
      return;
    }
    const resourceId = event.data?.resourceId ?? '';
    onSelectResource(resourceId);
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const dataSources = dataSourcesQuery.data?.response ?? [];
  const dataSourcesCount = dataSourcesQuery.data?.count ?? 0;

  return (
    <List<ResourceModel>
      version={ListVersions.v2}
      isLoading={dataSourcesQuery.isLoading || dataSourcesQuery.isRefetching}
      gridRef={gridRef}
      rowData={dataSources}
      columns={dataSourceColumns}
      pagination
      paginationProps={{
        totalRows: dataSourcesCount,
        currentPage: page,
        onPageNumberChange: handlePageNumberChange,
        labelRowsPerPage: 'Data sources',
        showPaginationLabel: true,
      }}
      paginationPageSize={PAGE_SIZE}
      onCellClicked={handleCellClick}
      onRowClicked={handleRowClick}
      leftActionsContent={
        <ActionsToolbar
          searchText={searchText}
          onSearchTextChange={setSearchText}
          isLoading={dataSourcesQuery.isLoading || dataSourcesQuery.isRefetching}
          onReload={dataSourcesQuery.refetch}
        />
      }
    />
  );
};
