import { copyEditEvents } from '../../../../../analytics/copyEditEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { FullPageLoader } from '../../../../../design-system';
import {
  Button,
  closeAllModals,
  Horizontal,
  notifications,
  openModal,
  TextArea,
  TextInput,
  useInputState,
  Vertical,
} from '../../../../../design-system/v2';
import { UpdateGenAIProjectRequest } from '../../../../../generated/api';
import { sendAnalytics } from '../../../../../initializers/analytics';
import { useUpdateAppProjectMutation } from '../../../../../queries/document-ai/document';

interface UpdateProjectModalProps {
  appId: string;
  projectId: string;
  oldProjectName?: string;
  oldProjectDescription?: string;
}

const UpdateProjectModal = ({
  appId,
  projectId,
  oldProjectName,
  oldProjectDescription,
}: UpdateProjectModalProps) => {
  const [projectName, setProjectName] = useInputState(oldProjectName ?? '');
  const [projectDescription, setProjectDescription] = useInputState(oldProjectDescription ?? '');
  const { workspaceId } = useAppMetadata();

  const { mutateAsync, isLoading } = useUpdateAppProjectMutation(appId, projectId);

  const handleSubmitUpdateModal = async () => {
    const req: UpdateGenAIProjectRequest = {
      projectName: projectName.trim(),
      projectDescription: projectDescription.trim(),
    };
    mutateAsync(req)
      .then(() => {
        notifications.success('Project updated successfully');
        closeAllModals();
      })
      .catch(() => {
        notifications.error('There was an issue updating the project');
      });
  };

  const handleCancelButtonClick = () => {
    sendAnalytics(copyEditEvents.project.cancel({ workspaceId }));
    closeAllModals();
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Updating your project details..." />}
      <Vertical spacing="xl">
        <TextInput
          required
          ariaLabel="Project Name"
          label="Project Name"
          placeholder="Project Name"
          value={projectName}
          onChange={setProjectName}
        />

        <TextArea
          ariaLabel="Project Description"
          label="Project Description"
          placeholder="Project description"
          minRows={2}
          maxRows={4}
          value={projectDescription}
          onChange={setProjectDescription}
        />

        <Horizontal position="right">
          <Button variant="secondary" onClick={handleCancelButtonClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmitUpdateModal}>
            Update
          </Button>
        </Horizontal>
      </Vertical>
    </>
  );
};

export const useUpdateAppProjectModal = (
  appId: string,
  projectId: string,
  oldProjectName: string,
  oldProjectDescription: string,
) => ({
  openUpdateAppProjectModal: () =>
    openModal({
      size: 'lg',
      title: 'Edit Project Details',
      children: (
        <UpdateProjectModal
          appId={appId}
          projectId={projectId}
          oldProjectName={oldProjectName}
          oldProjectDescription={oldProjectDescription}
        />
      ),
    }),
});
