import { useState } from 'react';
import { Box, closeAllModals, openModal } from '../../../design-system/v2';
import { AsyncDropDownResources } from '../../../generated/api';
import { useInvalidateDataSourcesList } from '../../../queries/data-sources';
import { CompleteParams } from '../../../queries/data-upload';
import { useInvalidateResourceLookup } from '../../../queries/resource-lookup';
import { FileUploader, Step } from '../../common/file-uploader/FileUploader';

interface DataSourcesFileModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
}

export const DataSourcesFileModal = ({ onFileUploadComplete }: DataSourcesFileModalProps) => {
  const invalidateDataSources = useInvalidateDataSourcesList();
  const invalidateResources = useInvalidateResourceLookup(AsyncDropDownResources.DataResources);
  const [width, setWidth] = useState('30vw');

  const onUploadQueued = () => {
    closeAllModals();
  };

  const onUploadComplete = (state: 'success' | 'error', completeParams: CompleteParams) => {
    invalidateDataSources();
    invalidateResources();
    onFileUploadComplete?.(state, completeParams);
  };

  const handleStepChange = (step: Step) => {
    step === 'preview' ? setWidth('75vw') : setWidth('30vw');
  };

  return (
    <Box w={width}>
      <FileUploader
        onUploadQueued={onUploadQueued}
        onUploadComplete={onUploadComplete}
        onStepChange={handleStepChange}
      />
    </Box>
  );
};

interface UseDataSourcesFileUploadModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
}

export const useDataSourcesFileUploadModal = (options?: UseDataSourcesFileUploadModalProps) => () =>
  openModal({
    title: 'Upload file',
    size: 'auto',
    children: <DataSourcesFileModal onFileUploadComplete={options?.onFileUploadComplete} />,
  });
