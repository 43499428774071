import { ColorFormat } from '@mantine/core/lib/ColorPicker/types';
import initial from 'lodash/initial';
import last from 'lodash/last';
import round from 'lodash/round';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export const toSentenceCase = (str: string) => startCase(toLower(str));

export const capitalize = (str: string) =>
  str.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : '';

// Displays up to 3 decimal places, and fewer if possible
export const formatNumber = (n: number, decimalPlaces = 3) => round(n, decimalPlaces);

export const formatNumberLocaleString = (n: number, decimalPlaces = 3) =>
  n.toLocaleString(undefined, {
    maximumFractionDigits: decimalPlaces,
  });

export const formatDate = (date: string | Date) => new Date(date).toLocaleDateString('en-US');

export const formatDateLong = (date: string | Date) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const getYearFromDate = (dateString: string): string => {
  if (dateString === 'n.d') {
    return dateString;
  }
  const splittedDate = dateString.split('-');
  const [day, month, year] =
    splittedDate.length === 1 ? [1, 1, Number(splittedDate)] : dateString.split('-').map(Number);

  // Create a Date object using the parsed values
  const dateObject = new Date(year, month - 1, day);
  return dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
  });
};

const formatTime = (date: string | Date) =>
  new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

export const formatDateTime = (date: string | Date) =>
  `${formatDateLong(date)} ${formatTime(date)}`;

const pluralRules = new Intl.PluralRules('en-US');

// Get string reporting number of items that respects grammatical number (singular/plural).
// Inspired by https://2ality.com/2019/12/intl-pluralrules.html
export const toPlural = (count: number, singular: string, plural = `${singular}s`) => {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return `${formatNumberLocaleString(count)} ${singular}`;
    case 'other':
      return `${formatNumberLocaleString(count)} ${plural}`;
    default:
      throw new Error('Unknown number: ' + grammaticalNumber);
  }
};

// Color
const gaugeColors = ['#FAAD4D', '#FF7055', '#54AD96'];

export const getGaugeColor = (n: number) => gaugeColors[n % gaugeColors.length];

const VALIDATION_REGEXP: Record<ColorFormat, RegExp> = {
  hex: /^#?([0-9A-F]{3}){1,2}$/i,
  hexa: /^#?([0-9A-F]{4}){1,2}$/i,
  rgb: /^rgb\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i,
  rgba: /^rgba\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i,
  hsl: /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/i,
  hsla: /^hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*(\d*(?:\.\d+)?)\)$/i,
};

export function isColorValid(color: string) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [, regexp] of Object.entries(VALIDATION_REGEXP)) {
    if (regexp.test(color)) {
      return true;
    }
  }
}

export const hexToRGB = (
  hexCode: string,
): [number, number, number] | [number, number, number, number] => {
  let r = '0',
    g = '0',
    b = '0',
    a = '0';
  const hexDigits = hexCode.slice(1);
  if (hexDigits.length === 3) {
    r = '0x' + hexDigits[0] + hexDigits[0];
    g = '0x' + hexDigits[1] + hexDigits[1];
    b = '0x' + hexDigits[2] + hexDigits[2];
  } else if (hexDigits.length === 6) {
    r = '0x' + hexDigits[0] + hexDigits[1];
    g = '0x' + hexDigits[2] + hexDigits[3];
    b = '0x' + hexDigits[4] + hexDigits[5];
  } else if (hexDigits.length === 8) {
    r = '0x' + hexDigits[0] + hexDigits[1];
    g = '0x' + hexDigits[2] + hexDigits[3];
    b = '0x' + hexDigits[4] + hexDigits[5];
    a = '0x' + hexDigits[6] + hexDigits[7];

    return [+r, +g, +b, +a];
  }
  return [+r, +g, +b];
};

export const getInterpolatedColor = (color: string, param: number) => {
  const [r, g, b] = hexToRGB(color);
  return `rgba(${r}, ${g}, ${b}, ${param})`;
};

// Inserts <wbr> (word break hint) into URL string, following the Chicago manual of style's line break guidelines.
// source: https://css-tricks.com/better-line-breaks-for-long-urls/
export const getUrlWithWbr = (url: string) => {
  const doubleSlash = url.split('//');

  const formatted = doubleSlash
    .map(str =>
      // Insert a word break opportunity...
      str
        // After a colon
        .replace(/(?<after>:)/giu, '$1<wbr>')
        // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
        .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
        // Before and after an equals sign or ampersand
        .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>'),
    )
    .join('//<wbr>');

  return formatted;
};

export const toCommaSeparatedList = (list: string[], joiner = 'and') => {
  if (list.length < 2) {
    return list.join('');
  }
  if (list.length === 2) {
    return list.join(` ${joiner} `);
  }
  const frontItems = initial(list);
  const lastItem = last(list);
  return `${frontItems.join(', ')}, ${joiner} ${lastItem}`;
};
