import { FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import { flushSync } from 'react-dom';
import {
  createStyles,
  TextInput,
  TextInputProps,
  TextVariants,
  useInputState,
} from '../../design-system/v2';
import { useTextStyles } from '../../design-system/v2/core/typography/Text.style';

interface InputParams {
  value: string;
}

const useTextInputStyles = createStyles<'root' | 'wrapper' | 'input', InputParams>(
  (theme, { value }) => ({
    root: {
      ':hover': {
        outline: `1px solid ${theme.colors.gray[4]}`,
      },

      ':focus-within': {
        outline: `1px solid ${theme.colors.blue[7]}`,
      },
    },
    wrapper: {
      // The following CSS is for auto growing text input.
      // This layout is inspired from https://codepen.io/shshaw/pen/bGNJJBE
      // Because we are using pseudo elements, it may create issues for users using
      // screen readers. Need to switch to the JS approach if users complaint about this.
      // TODO: Need to create a new component <AutoGrowTextInput />
      // to decouple the auto grow logic from this component
      // so it can be used in other cases as well
      display: 'inline-grid',

      '&::after': {
        content: `"${value}"`,
        padding: '1px 0.5rem',
        gridArea: '1 / 2',
        width: 'auto',
        visibility: 'hidden',
        whiteSpace: 'pre',
        overflow: 'hidden',
      },
    },
    input: {
      gridArea: '1 / 2',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

interface EditableTextProps extends TextInputProps {
  variant?: Exclude<TextVariants, 'textLink'>;
  value: string;
  onSave: (newValue: string) => Promise<unknown>;
}

export const EditableText = ({
  value,
  onSave,
  variant = 'bodyShort02',
  ...rest
}: EditableTextProps) => {
  const [inputValue, setInputValue] = useInputState(value);
  const { classes: textClasses } = useTextStyles({ variant });
  const [isProgrammaticBlur, setProgrammaticBlur] = useState(false);
  const { classes, cx } = useTextInputStyles({ value: inputValue });
  const classNames = { ...classes, input: cx(classes.input, textClasses.root) };

  const handleSave = async () => {
    if (!inputValue && rest.required) {
      // Revert to the previously saved value when attempting to save an empty string
      // TODO: Let the caller dictate how they want to handle empty strings
      setInputValue(value);
      return;
    }

    try {
      await onSave(inputValue);
    } catch {
      // Revert to the previously saved value if save fails
      setInputValue(value);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      if (e.key === 'Enter') {
        handleSave();
      } else if (e.key === 'Escape') {
        // Reset the value on esc
        setInputValue(value);
      }
      // Here, the input is forced to lose focus.
      // But we don't want the handleBlur function to call the handleSave function.
      // Hence we use this flag to have conditional execution in the handleBlur function
      flushSync(() => setProgrammaticBlur(true));
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (!isProgrammaticBlur) {
      handleSave();
    }
    setProgrammaticBlur(false);
  };

  return (
    <TextInput
      {...rest}
      // Mantine does not allow us to pass the input size attribute.
      // Hence it is updated using the ref.
      // We need to set the size to 1 to override the default size
      // that controls the width of the input
      // With size set to 1, input width will be as big as the content inside it
      // Reference: https://stackoverflow.com/a/29990524
      ref={ref => {
        if (ref) {
          ref.size = 1;
        }
      }}
      // TODO: The following code will override the passed props.
      // Need to handle them as and when the need arises.
      classNames={classNames}
      value={inputValue}
      onChange={setInputValue}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      variant="unstyled"
    />
  );
};
