import { useState } from 'react';
import { getCitationStyleDetails } from '../../../../../components/app-store/document-ai/commons/citations/util';
import { ReferenceComponent } from '../../../../../components/app-store/document-ai/copy-edit/details/suggestions-panel/panels/ask-markov/CopyEditReferenceSection';
import { Box, Popover, Text } from '../../../../../design-system/v2';
import { CitationStyleGuideType, CopyEditCitation } from '../../../../../generated/api';

export const ReferenceText = ({ value }: { value: string }) => (
  <span style={{ color: 'blue' }}>{value}</span>
);

interface MarkovReferenceItemProps {
  citation: CopyEditCitation;
  citationStyles: CitationStyleGuideType;
}

export const MarkovReferenceItem = ({
  citation,
  citationStyles,
}: MarkovReferenceItemProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { getInlineCitationText } = getCitationStyleDetails(citationStyles);

  const handleOnClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <Box component="span" style={{ position: 'relative', display: 'inline-block' }}>
      <Popover
        width={200}
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        offset={4}
        styles={{
          dropdown: {
            maxWidth: '100%', // Ensure the dropdown doesn't exceed the parent width
            wordWrap: 'break-word', // Wrap words within the dropdown
            whiteSpace: 'normal', // Allow text to wrap to the next line
          },
        }}
      >
        <Popover.Target>
          <Text
            variant="bodyShort03"
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={handleOnClick}
          >
            {getInlineCitationText(citation.citation)}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <ReferenceComponent referenceObj={citation} referenceStyle={citationStyles} />
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};
